/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file UiProps.ts
 * @desc Created on Fri Sep 23 2022 16:23:35
 * @copyright All rights reserved @ Inarix
 */

import { AlertColor } from '@mui/material';

export interface SnackBarProps {
  open: boolean;
  severity: AlertColor;
  message: string;
}

export enum JobTabNames {
  job = 0,
  labels = 1,
}

export enum ModalNames {
  closeJob,
  addFreeText,
}
