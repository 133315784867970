/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file userSlice.ts
 * @desc Created on Tue May 24 2022 18:28:33
 * @copyright All rights reserved @ Inarix
 */
import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { RootState } from '../store';
import {
  authHeader,
  refreshTokenUrl,
  userLoginUrl,
  userDetailsUrl,
  requestAccessUrl,
} from '../utils/queries';
import { decodeLoginToken } from '../utils/tokens';
import { addBaseThunkCases } from '../utils/thunks';
import { User, TokenContents, LoginFromAPI, UserFromAPI } from '../../declarations/User';
import LoginFormFields from '../../declarations/LoginFormFields';
import { QueriableItem } from '../../declarations/QueriableItem';
import { queryWrap, errorWrap } from '../utils/queryWrapper';

export const initialState: { data: User } & QueriableItem & { form: LoginFormFields } = {
  data: {
    id: '',
    orgId: 0,
    token: '',
    expires: 0,
    firstName: '',
    lastName: '',
  },
  status: 'unfetched',
  form: {
    hadPassInput: false,
    password: '',
    hadLoginInput: false,
    username: '',
  },
};

// selectors
export const selectUser = (state: RootState): typeof state.user.data => state.user.data;
export const selectUserErr = (state: RootState): typeof state.user.error => state.user.error;
export const selectLoginHadInput = (state: RootState): typeof state.user.form.hadLoginInput =>
  state.user.form.hadLoginInput;
export const selectPassHadInput = (state: RootState): typeof state.user.form.hadPassInput =>
  state.user.form.hadPassInput;
export const selectUsername = (state: RootState): typeof state.user.form.username =>
  state.user.form.username;
export const selectUserPassword = (state: RootState): typeof state.user.form.password =>
  state.user.form.password;
export const selectUserStatus = (state: RootState): typeof state.user.status => state.user.status;

// thunks
export const refreshUser = createAsyncThunk('user/refresh', async (oldToken: string, store) => {
  if (!oldToken) {
    throw new Error('Please login');
  }

  const expires =
    (store.getState() as RootState).user.data.expires || decodeLoginToken(oldToken).expires;

  if (expires < Date.now()) {
    throw new Error('Session expired');
  }

  const token = (
    (await queryWrap(axios.put(refreshTokenUrl, {}, authHeader(oldToken)))).data as LoginFromAPI
  ).token;
  return {
    token,
    ...decodeLoginToken(token),
  };
});
export const connectUser = createAsyncThunk(
  'user/connect',
  async (_data: null | undefined, store) => {
    const { username, password } = (store.getState() as RootState).user.form;
    if (!username || !password) {
      throw new Error('Empty fields');
    }

    let token = '';
    token = (
      (await queryWrap(axios.post(userLoginUrl, { username, password }))).data as LoginFromAPI
    ).token;

    const response = await queryWrap(axios.get(requestAccessUrl, authHeader(token)));
    if (response.status < 200 || 300 < response.status) {
      errorWrap({ response });
    }

    const tokenContents = decodeLoginToken(token);
    return {
      token,
      ...tokenContents,
      ...((await queryWrap(axios.get(userDetailsUrl(tokenContents.userId), authHeader(token))))
        .data as UserFromAPI),
    };
  },
);

// note: doc for typings here https://redux-toolkit.js.org/usage/usage-with-typescript#defining-action-contents-with-prepare-callbacks
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (_, action: PayloadAction<string | undefined>): typeof initialState => {
      if (!action.payload) {
        return initialState;
      }
      return {
        ...initialState,
        error: action.payload,
      };
    },
    usernameInput: (state, action: PayloadAction<string>): void => {
      state.form.username = action.payload;
      state.form.hadLoginInput = state.form.hadLoginInput || !!action.payload;
    },
    passwordInput: (state, action: PayloadAction<string>): void => {
      state.form.password = action.payload;
      state.form.hadPassInput = state.form.hadPassInput || !!action.payload;
    },
  },
  extraReducers(builder) {
    addBaseThunkCases(
      builder,
      [refreshUser, connectUser],
      (
        state: WritableDraft<typeof initialState>,
        action: PayloadAction<{ token: string } & TokenContents & Partial<UserFromAPI>, string>,
      ): void => {
        const payload = action.payload;
        state.data.id = payload.userId as string;
        state.data.orgId = payload.realmId;
        state.data.token = payload.token;
        state.data.expires = payload.expires;
        state.status = 'fulfilled';
        state.form = initialState.form;

        if (payload.firstname) {
          state.data.firstName = payload.firstname;
        }
        if (payload.lastname) {
          state.data.lastName = payload.lastname;
        }
      },
    );
  },
});

// actions
export const { logout, usernameInput, passwordInput } = userSlice.actions;
export default userSlice.reducer;
