/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ImageThumb.tsx
 * @desc Created on Wed Jun 08 2022 22:54:46
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEvent, ReactElement } from 'react';
import { Image } from '../../declarations/Image';
import { useAppDispatch } from '../../redux/hooks';
import { toggleSelected } from '../../redux/slices/imagesSlice';
import { ctrlKey } from '../utils/device';

const ImageThumb: FC<{ img: Image; idx: number }> = (props) => {
  const dispatch = useAppDispatch(),
    click = (e: MouseEvent, idx: number): void => {
      e.preventDefault();
      (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
      dispatch(toggleSelected({ idx, shift: e.shiftKey, ctrl: e[ctrlKey] }));
    };
  const classList: string[] = [];
  if (props.img.selected) {
    classList.push('selected');
  }
  if (
    (props.children as ReactElement[]).length &&
    (props.children as ReactElement[]).filter((val) => null !== val).length
  ) {
    classList.push('labelled');
  }

  return (
    <div
      id={props.img.id}
      className={classList.join(' ')}
      style={{ backgroundImage: `url(${props.img.url})` }}
      onClick={(e): void => click(e, props.idx)}
    >
      {props.children}
    </div>
  );
};

export default ImageThumb;
