/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Image.ts
 * @desc Created on Tue May 24 2022 18:41:03
 * @copyright All rights reserved @ Inarix
 */
import { ImageMetadata } from './Exif';
import { Acquisition } from './Job';

// this one can be imageId, but can be something else for computing purposes
export type CompositeId = string;

export interface Image {
  fileId: number;
  id: CompositeId;
  imageId: string;
  sampleId: string | null;
  createdAt: string;
  selected?: boolean;
  url: string;
  labels: Record<string, boolean>;
  dots?: number[][];
  masks?: number[][];
}

export interface DerivedImage extends Image {
  objectId: string | null;
  objectViewId: string | null;
  fileName: string;
  box: number[];
  origin: string; // original image's UUID
}

export interface ImageFromAPI {
  id: string;
  fileId: number;
  acquisitionId: string;
  categoryId: number;
  originPastActionId: string;
  derivationOrder: number;
  metadata: ImageMetadata;
  isTainted: true;
  createdAt: string;
  updatedAt: string;
}
export interface ImageWithEagerFromAPI extends ImageFromAPI {
  acquisition: Acquisition; // TODO maybe this is an array
}

export interface ImageUrlFromAPI {
  signedRequest: string;
}

export function compositeIdFactory(imgId: string, objIdx?: number): CompositeId {
  return objIdx === undefined ? imgId : `${imgId}_${objIdx}`;
}

export function toImageFromAPI(
  img: ImageFromAPI,
  options?: {
    objectIdx?: number;
    file?: string;
    sampleId?: string;
    objectId?: string;
    objectViewId?: string;
    originId?: string;
    box?: number[];
  },
): Image | DerivedImage {
  const _img: Image | DerivedImage = {
    id: compositeIdFactory(img.id, options?.objectIdx),
    imageId: img.id,
    fileId: img.fileId,
    sampleId: options?.sampleId || (img as ImageWithEagerFromAPI).acquisition?.sampleId,
    createdAt: img.createdAt,
    url: '',
    labels: {},
  };
  if (options?.originId) {
    (_img as DerivedImage).fileName = options.file || '';
    (_img as DerivedImage).objectId = options.objectId || '';
    (_img as DerivedImage).objectViewId = options.objectViewId || '';
    (_img as DerivedImage).origin = options.originId;
    (_img as DerivedImage).box = options.box || [];
  }
  return _img;
}
