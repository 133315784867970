/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file JobDetails.tsx
 * @desc Created on Mon Jun 13 2022 17:00:51
 * @copyright All rights reserved @ Inarix
 */

import { FC, SyntheticEvent } from 'react';
import { JobTabNames } from '../../declarations/UiProps';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectJobTab, setJobTab } from '../../redux/slices/uiSlice';
import { Tab, Tabs } from '@mui/material';
import JobTab from './JobTab';
import LabelsTab from './LabelsTab';

const JobDetails: FC = () => {
  const dispatch = useAppDispatch(),
    tab = JobTabNames[useAppSelector(selectJobTab)];

  const changeTab = (e: SyntheticEvent, value: number): void => {
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
    dispatch(setJobTab(JobTabNames[value] as 'job' | 'labels'));
  };

  return (
    <div id="image_props">
      <Tabs value={tab} onChange={changeTab} variant="fullWidth">
        <Tab label="Job" />
        <Tab label="Labels" />
      </Tabs>
      <JobTab />
      <LabelsTab />
    </div>
  );
};

export default JobDetails;
