/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file VoidHandlerEffect.tsx
 * @desc Created on Wed Jul 06 2022 18:19:38
 * @copyright All rights reserved @ Inarix
 */

import { FC, useEffect } from 'react';

const VoidHandlerEffect: FC<{ event: string; handler: (e: any) => any }> = (props) => {
  useEffect(() => {
    window.addEventListener(props.event, props.handler);
    return (): void => {
      window.removeEventListener(props.event, props.handler);
    };
  });

  return null;
};

export default VoidHandlerEffect;
