/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file pastActionSlice.ts
 * @desc Created on Mon Jun 20 2022 15:52:24
 * @copyright All rights reserved @ Inarix
 */

import axios from 'axios';
import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { WritableDraft } from 'immer/dist/internal';

import { PastAction } from '../../declarations/PastAction';
import { QueriableItem } from '../../declarations/QueriableItem';
import { pastActionUrl } from '../utils/queries';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';
import { RootState } from '../store';
import { queryWrap } from '../utils/queryWrapper';
import { PAST_ACTION_SOURCE } from '../../Conf';

// default state
export const initialState: { data: Partial<PastAction> } & QueriableItem = {
  status: 'unfetched',
  data: {}, // TODO maybe storing the ID is enough
};

// selectors
export const selectPastAction = (state: RootState): typeof initialState.data =>
  state.pastAction.data;
export const selectPAStatus = (state: RootState): typeof initialState.status =>
  state.pastAction.status;

const _finishPastAction = async (
  store: BaseThunkAPI<unknown, any, Dispatch, unknown>,
  prop: 'end' | 'abort',
): Promise<PastAction | undefined> => {
  const { state, authHead } = thunkInit(store);
  const id = state.pastAction.data.id;
  if (!id) {
    throw new Error('No past action configured');
  }
  return (
    await queryWrap(
      axios.patch(
        pastActionUrl(id),
        { running: false, [prop]: 1 } as Partial<PastAction>,
        authHead,
      ),
    )
  ).data as PastAction;
};

// thunks
export const createPastAction = createAsyncThunk(
  'pastAction/create',
  async (_: null | undefined, store) => {
    const { state, authHead } = thunkInit(store);
    if (!state.jobs.current) {
      throw new Error('No job selected');
    }

    return (
      await queryWrap(
        axios.post(
          pastActionUrl(),
          {
            // creatorId & ownerOrgId are added by the API
            running: true,
            originScenarioInstanceId: state.job.scenarioInstanceId,
            userNotes: 'Labeling tool session',
            sourceConstantId: PAST_ACTION_SOURCE,
            metadata: {},
          } as Partial<PastAction>,
          authHead,
        ),
      )
    ).data as PastAction;
  },
);
export const endPastAction = createAsyncThunk(
  'pastAction/end',
  async (_: null | undefined, store) => {
    return await _finishPastAction(store, 'end');
  },
);
export const abortPastAction = createAsyncThunk(
  'pastAction/abort',
  async (_: null | undefined, store) => {
    return await _finishPastAction(store, 'abort');
  },
);

// slice
const pastActionSlice = createSlice({
  name: 'pastAction',
  initialState,
  reducers: {
    clearPastAction: (): typeof initialState => {
      return initialState;
    },
    setPastAction: (state, action: PayloadAction<PastAction>): void => {
      state.data = action.payload;
      state.status = 'fulfilled';
    },
  },
  extraReducers(builder) {
    addBaseThunkCases(
      builder,
      [createPastAction, endPastAction, abortPastAction],
      (state: WritableDraft<typeof initialState>, action: PayloadAction<PastAction>): void => {
        state.status = 'fulfilled';
        state.data = action.payload;
      },
    );
  },
});

// actions
export const { clearPastAction, setPastAction } = pastActionSlice.actions;
export default pastActionSlice.reducer;
