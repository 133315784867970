/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file AuthenticatedApp.tsx
 * @desc Created on Tue May 24 2022 19:06:13
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import Sidebar from '../sections/Sidebar';
import SnackContainer from '../sections/SnackContainer';
import TopRight from '../sections/TopRight';
import JobPage from './JobPage';
import ZoomDetector from '../utils/ZoomDetector';

const AuthenticatedApp: FC = () => {
  return (
    <>
      <div className="flex">
        <Sidebar />
        <JobPage />
      </div>
      <TopRight />
      <SnackContainer />
      <ZoomDetector />
    </>
  );
};

export default AuthenticatedApp;
