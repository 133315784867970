/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file CopiableText.tsx
 * @desc Created on Thu Sep 22 2022 17:20:00
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { openSnack } from '../../redux/slices/uiSlice';
import { writeClipboard } from '../utils/clipboard';

const CopiableText: FC<{ content?: string }> = (props) => {
  const dispatch = useAppDispatch();
  const copy: MouseEventHandler = async (e) => {
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
    try {
      await writeClipboard((e.target as HTMLElement).innerText);
      dispatch(openSnack({ message: 'Data copied to your clipboard!', type: 'success' }));
    } catch (e) {
      dispatch(openSnack({ message: 'Error while copying to your clipboard', type: 'warning' }));
    }
  };

  return (
    <span onClick={copy} title="Click to copy to the clipboard" className="pointer">
      {props.content || props.children}
    </span>
  );
};

export default CopiableText;
