/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file clipboard.ts
 * @desc Created on Thu Sep 22 2022 17:30:55
 * @copyright All rights reserved @ Inarix
 */

async function checkPermission(): Promise<void> {
  // firefox doesn't comply with the 'clipboard-write' permission
  if (!navigator.permissions || /firefox/i.test(navigator.userAgent)) {
    return;
  }

  // lib.dom.d.ts isn't up-to-date with the permissions enum
  const result = await navigator.permissions.query({ name: 'clipboard-write' as any });
  if ('denied' == result.state) {
    throw new Error();
  }
}

export async function writeClipboard(data: string): Promise<void> {
  await checkPermission();
  await navigator.clipboard.writeText(data);
}
