/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file CloseJobModal.tsx
 * @desc Created on Thu Sep 29 2022 22:18:11
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { endPastAction, selectPAStatus } from '../../redux/slices/pastActionSlice';
import { selectCurrentModal, setModal } from '../../redux/slices/uiSlice';
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import BaseModal from './Modal';
import ReduxButton from '../atomic/io/ReduxButton';
import { selectLength, selectProgress } from '../../redux/slices/imagesSlice';
import { selectJobsStatus } from '../../redux/slices/jobsSlice';

const CloseJobModal: FC = () => {
  const modal = useAppSelector(selectCurrentModal),
    imageCount = useAppSelector(selectLength),
    progress = useAppSelector(selectProgress),
    jobsStatus = useAppSelector(selectJobsStatus),
    actionStatus = useAppSelector(selectPAStatus),
    disable = 'pending' === jobsStatus || 'pending' === actionStatus;

  return (
    <BaseModal open={'closeJob' === modal} action={setModal} value={''}>
      <Card
        variant="outlined"
        sx={{ width: 400, margin: 'auto', backgroundColor: 'white', color: 'black' }}
      >
        <CardHeader title="Close job" />
        <CardContent>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            {imageCount - progress} images
          </Typography>
          <Typography component="span">&nbsp;have no labels.</Typography>
          <Typography>Are you sure you want to close the job? This cannot be undone.</Typography>
          <Typography>Your current work has been saved.</Typography>
        </CardContent>
        <CardActions>
          <ReduxButton
            disabled={disable}
            action={setModal}
            value={''}
            mui={{ variant: 'outlined', fullWidth: true }}
          >
            Cancel
          </ReduxButton>
          <ReduxButton disabled={disable} action={endPastAction} mui={{ fullWidth: true }}>
            {disable ? 'Saving...' : 'Yes'}
          </ReduxButton>
        </CardActions>
      </Card>
    </BaseModal>
  );
};

export default CloseJobModal;
