/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file App.tsx
 * @desc Created on Tue May 24 2022 19:06:08
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectUser, selectUserStatus } from '../../redux/slices/userSlice';
import '../../App.css';
import { darkThemeInarix } from '../MuiTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AuthenticatedApp from './AuthenticatedApp';
import LoginForm from './LoginForm';

const App: FC = () => {
  const user = useAppSelector(selectUser);
  const status = useAppSelector(selectUserStatus);
  return (
    <ThemeProvider theme={darkThemeInarix}>
      <CssBaseline />
      {!user.token || 'rejected' == status ? <LoginForm /> : <AuthenticatedApp />}
    </ThemeProvider>
  );
};

export default App;
