/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Deselecter.tsx
 * @desc Created on Tue Jul 26 2022 16:50:17
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler, useRef } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { toggleSelected } from '../../../redux/slices/imagesSlice';
import VoidHandlerEffect from './VoidHandlerEffect';

const Deselecter: FC = () => {
  const dispatch = useAppDispatch();
  const pos = useRef([] as number[]);
  const handler: MouseEventHandler = (e) => {
    if ('mousedown' === e.type) {
      pos.current = [e.clientX, e.clientY];
    } else {
      if (!(e as unknown as Record<string, any>).skipDeselect) {
        // Pythagoras, but without square root, because it's long to process and we don't need it
        const dist =
          Math.pow(pos.current[0] - e.clientX, 2) + Math.pow(pos.current[1] - e.clientY, 2);
        if (dist < 200) {
          dispatch(toggleSelected());
        }
        pos.current = [];
      }
    }
  };

  return (
    <>
      <VoidHandlerEffect handler={handler} event="mousedown" />
      <VoidHandlerEffect handler={handler} event="click" />
    </>
  );
};

export default Deselecter;
