/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file labelTemplate.ts
 * @desc Created on Wed May 10 2023 17:43:30
 * @copyright All rights reserved @ Inarix
 */

import { SimpleLabelTemplate, VirtualLabelTemplate } from '../../declarations/LabelTemplate';

export function simpleLabelTemplateFactory(
  tpl: { id: string } & VirtualLabelTemplate,
): SimpleLabelTemplate {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userFacingName, ...configRest } = tpl.generatedByLabelTemplateConfig as any;
  return {
    id: tpl.id,
    versionId: tpl.labelTemplateVersionId,
    type: tpl.type.value,
    scope: tpl.scope.value,
    config: configRest,
    metadata: tpl.labelInstanceMetadataSeed,
  };
}
