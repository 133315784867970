/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file JobTab.tsx
 * @desc Created on Sat Sep 24 2022 01:58:22
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { Job } from '../../declarations/Job';
import { JobTabNames } from '../../declarations/UiProps';
import { useAppSelector } from '../../redux/hooks';
import { selectLength, selectProgress } from '../../redux/slices/imagesSlice';
import { selectCurrentJob } from '../../redux/slices/jobsSlice';
import { selectJobTab } from '../../redux/slices/uiSlice';
import { List } from '@mui/material';
import CopiableText from '../atomic/CopiableText';
import StartCloseJob from './StartCloseJob';
import DownloadLink from '../atomic/DownloadLink';
import listItemFactory from '../utils/listItemFactory';

const JobTab: FC = () => {
  const job = useAppSelector(selectCurrentJob) as Job,
    imageCount = useAppSelector(selectLength),
    progress = useAppSelector(selectProgress),
    tab = JobTabNames[useAppSelector(selectJobTab)] as number;

  return (
    <div hidden={JobTabNames.job !== tab}>
      <p>{job.shortInstructions}</p>
      <DownloadLink />
      <StartCloseJob />
      <h4>Information</h4>
      <List dense disablePadding sx={{ color: 'text.secondary' }}>
        {listItemFactory('Id', <CopiableText content={job.id} />)}
        {listItemFactory('Deadline', job.expireAt || '')}
        {listItemFactory('# of images', imageCount)}
        {listItemFactory(
          'Labeled',
          `${Math.round((progress / imageCount) * 100)}% (${progress}/${imageCount})`,
        )}
      </List>
    </div>
  );
};

export default JobTab;
