/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file labelInstancesSlice.ts
 * @desc Created on Tue May 24 2022 18:41:19
 * @copyright All rights reserved @ Inarix
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IMAGES_PER_PAGE } from '../../Conf';
import { CompositeId, Image } from '../../declarations/Image';
import {
  DeprecationBody,
  LabelInstance,
  labelInstanceFactory,
  LabelInstanceParams,
  labelTargetIdToCompositeId,
} from '../../declarations/labelInstance';
import { QueriableItem } from '../../declarations/QueriableItem';
import { RootState } from '../store';
import {
  deprecateBody,
  deprecateUrl,
  findUrl,
  labelInstanceGetBody,
  labelInstanceUrl,
} from '../utils/queries';
import { queryWrap } from '../utils/queryWrapper';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';

export const initialState: {
  data: Record<CompositeId, Record<string, LabelInstance>>;
} & QueriableItem = {
  status: 'unfetched',
  data: {},
};

// selectors
export const selectLabelInstances = (
  state: RootState,
  id?: CompositeId,
): Record<string, LabelInstance> | undefined => {
  if (!id) {
    return;
  }
  return state.labelInstances.data[id];
};

// thunks
export const fetchInstances = createAsyncThunk(
  'labelInstances/fetch',
  async (ids: LabelInstanceParams, store) => {
    if (!ids.samples.length && !ids.templates.length && !ids.objectViews.length) {
      return { labels: [], idMap: {} as Record<string, string> };
    }

    const { authHead, state } = thunkInit(store);
    return {
      idMap: state.images.labelTargetIdToCompositeId,
      labels: (
        await queryWrap(
          axios.post(findUrl, labelInstanceGetBody(ids, state.jobs.current), authHead),
        )
      ).data.data as LabelInstance[],
    };
  },
);
export const saveInstances = createAsyncThunk(
  'labelInstances/save',
  async (usePrevious: boolean | undefined, store) => {
    const { state, authHead } = thunkInit(store);
    const pageIdx =
      (usePrevious ? state.images.previousPage : state.images.currentPage) * IMAGES_PER_PAGE;
    const templatesMap = state.labelTemplates.templateMap;
    const templateValues = state.labelTemplates.templateData;
    const pastActionId = state.pastAction.data.id as string;
    const user = state.user.data;
    const jobId = state.jobs.current;

    const toInsert: Partial<LabelInstance>[] = [];
    const toDeprecate: DeprecationBody[] = [];
    const deprecated: Partial<LabelInstance>[] = [];

    state.images.order.slice(pageIdx, pageIdx + IMAGES_PER_PAGE).forEach((compId) => {
      const image = state.images.data[compId] as Image;
      const instances = state.labelInstances.data[compId] as Record<string, LabelInstance>;
      const untouchedValues: Record<string, boolean> = {};

      // search for existing image/sample label instance to either skip inserting, or to deprecate
      for (const id in instances) {
        const inst = instances[id] as LabelInstance;
        if (image.labels[inst.rawInput]) {
          untouchedValues[inst.rawInput] = true;
        } else {
          toDeprecate.push(deprecateBody(id, jobId));
          deprecated.push({ id, imageId: inst.imageId, objectViewId: inst.objectViewId });
        }
      }

      // read values attached to the image and prepare data to send
      for (const label in image.labels) {
        if (!untouchedValues[label] && image.labels[label]) {
          toInsert.push(
            labelInstanceFactory(
              templateValues[templatesMap[label] as number],
              image,
              user,
              pastActionId,
              label,
            ),
          );
        }
      }
    });

    const result = await Promise.all([
      toDeprecate.length ? queryWrap(axios.patch(deprecateUrl, toDeprecate, authHead)) : null,
      toInsert.length ? queryWrap(axios.post(labelInstanceUrl, toInsert, authHead)) : { data: [] },
    ]);

    return {
      deprecated,
      inserted: result[1].data as LabelInstance[],
      idMap: state.images.labelTargetIdToCompositeId,
    };
  },
);

// slice
const labelInstancesSlice = createSlice({
  name: 'labelInstances',
  initialState,
  reducers: {
    clearInstances: (): typeof initialState => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    addBaseThunkCases(builder, [fetchInstances, saveInstances]);

    builder
      .addCase(fetchInstances.fulfilled, (state, action): void => {
        state.status = 'fulfilled';

        action.payload.labels.forEach((labelInst) => {
          const imgId = labelTargetIdToCompositeId(labelInst, action.payload.idMap);
          if (!state.data[imgId]) {
            state.data[imgId] = {};
          }
          (state.data[imgId] as Record<string, LabelInstance>)[labelInst.id as string] = labelInst;
        });
      })
      .addCase(saveInstances.fulfilled, (state, action): void => {
        state.status = 'fulfilled';

        action.payload.inserted.forEach((labelInst) => {
          const imgId = labelTargetIdToCompositeId(labelInst, action.payload.idMap);
          if (!state.data[imgId]) {
            state.data[imgId] = {};
          }
          (state.data[imgId] as Record<string, LabelInstance>)[labelInst.id as string] = labelInst;
        });

        action.payload.deprecated.forEach((labelInst) => {
          const imgId = labelTargetIdToCompositeId(
            labelInst as LabelInstance,
            action.payload.idMap,
          );
          if (state.data[imgId]) {
            delete (state.data[imgId] as Record<string, LabelInstance>)[labelInst.id as string];
          }
        });
      });
  },
});

// actions
export const { clearInstances } = labelInstancesSlice.actions;
export default labelInstancesSlice.reducer;
