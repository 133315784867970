/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Version.tsx
 * @desc Created on Thu Oct 06 2022 10:57:16
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import pkg from '../../../package.json';

const Version: FC = () => {
  return <div id="top_right">{`v${pkg.version}`}</div>;
};

export default Version;
