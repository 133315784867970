/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Constant.ts
 * @desc Created on Wed Jun 22 2022 19:52:39
 * @copyright All rights reserved @ Inarix
 */

export enum LabelType {
  categorical = 1,
  continuous,
  freetext,
  json,
  'image/file',
}

export enum LabelScope {
  sample = 15,
  image,
  // eslint-disable-next-line @typescript-eslint/camelcase
  object_view,
  object,
}

export enum JobStatus {
  open = 19,
  current,
  suspended,
  closed,
  aborted,
}

export type LabelTypeValue = keyof typeof LabelType;
export type LabelScopeValue = keyof typeof LabelScope;
export type JobStatusValue = keyof typeof JobStatus;

export interface Constant {
  id: number;
  value: string;
  originTableName: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
}

export interface ConstantLabelType extends Constant {
  value: LabelTypeValue;
}

export interface ConstantLabelScope extends Constant {
  value: LabelScopeValue;
}

export interface ConstantJobStatus extends Constant {
  value: JobStatusValue;
}
