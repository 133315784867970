/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file date.ts
 * @desc Created on Tue Aug 23 2022 19:20:11
 * @copyright All rights reserved @ Inarix
 */

// mmmmaybe we didn't need a function for that ^^'
export function formatDate(isoDate: string): string {
  return new Date(isoDate).toLocaleString();
}

export function formatHour(msTime: number): string {
  return new Date(msTime).toUTCString().substring(17, 25);
}
