/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file objectViewsSlice.ts
 * @desc Created on Thu Jun 30 2022 19:21:03
 * @copyright All rights reserved @ Inarix
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueriableItem } from '../../declarations/QueriableItem';

// this slice might have become useless. To be replaced by Qdrant's one?
export const initialState: { data: Record<string, boolean> } & QueriableItem = {
  status: 'unfetched',
  data: {},
};

const objectViewsSlice = createSlice({
  name: 'objectViews',
  initialState,
  reducers: {
    clearObjViews: (): typeof initialState => {
      return initialState;
    },
    initObjViews: (state, action: PayloadAction<Record<string, boolean>>): void => {
      state.data = action.payload;
    },
  },
});

export const { clearObjViews, initObjViews } = objectViewsSlice.actions;
export default objectViewsSlice.reducer;
