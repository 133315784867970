/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file BigImage.tsx
 * @desc Created on Fri Jun 17 2022 17:42:03
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentImage } from '../../redux/slices/imagesSlice';

const BigImage: FC = () => {
  const image = useAppSelector(selectCurrentImage);
  if (!image) {
    return null;
  }
  return <div id="big_image" style={{ backgroundImage: `url(${image.url})` }}></div>;
};

export default BigImage;
