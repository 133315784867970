/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file queries.ts
 * @desc Created on Tue May 24 2022 18:36:28
 * @copyright All rights reserved @ Inarix
 */
import { API } from '../../Conf';
import { DerivedImage, Image } from '../../declarations/Image';
import { FileLocation } from '../../declarations/Job';
import {
  DeprecationBody,
  LabelInstance,
  LabelInstanceParams,
} from '../../declarations/labelInstance';

// function makeArrayParam(key: string, values: string[], operator?: '$in' | '$nin'): string {
//   if (!values.length) {
//     return '';
//   }
//   const sep = operator ? `&${key}[${operator}][]=` : `&${key}[]=`;
//   return sep + values.join(sep);
// }

export function authHeader(token: string): {} {
  return { headers: { Authorization: `Bearer ${token}` } };
}

/*
  USER stuff
*/
export const refreshTokenUrl = `${API}/users/refresh-token`;
export const userLoginUrl = `${API}/users/login`;
// hard coded names here >:(
export const requestAccessUrl = `${API}/users/request-access?perms[0][name]=Job Instance Service&perms[0][scopes][]=get&perms[0][scopes][]=find&perms[0][scopes][]=patch`;
export function userDetailsUrl(userId?: string): string {
  return `${API}/users/user/${userId}`;
}

/*
  JOBS stuff
*/
const jobServiceUrl = `${API}/tools/job-instance`;
export const jobsListUrl = `${jobServiceUrl}?$limit=1000&$sort[createdAt]=-1`; // tmp fix?
export function jobUrl(id: string): string {
  return `${jobServiceUrl}/${id}`;
}
export function jobWithEagerUrl(id: string): string {
  // return `${jobServiceUrl}/${id}?$joinEager=[imagesData,objectViewsData,samplesData,labelTemplatesData]`;
  return `${jobServiceUrl}/${id}?$joinEager=[imagesData,objectViewsData,samplesData,pastAction,docFileLocation,scenario]`;
}

/*
  PAST ACTION stuff
*/
export function pastActionUrl(id?: string): string {
  return `${API}/samples/past-action${id ? `/${id}` : ''}`;
}

/*
  IMAGES stuff
*/
export function imageSignedUrl(img: Image): string {
  return `${API}/tools/file-manager/${img.fileId}${
    (img as DerivedImage).fileName
      ? `?suffix=${(img as DerivedImage).fileName}&mimeType=image/jpeg`
      : ''
  }`;
}
export function docSignedUrl(doc: FileLocation): string {
  return `${API}/tools/file-manager/${doc.id}?${doc.mimeType}`;
}

/*
  LABEL TEMPLATES stuff
*/
export function labelTemplateUrl(id: string, scenarioId?: number): string {
  return `${API}/v2/labels/label-template/${id}?virtual=true${
    scenarioId ? `&scenarioId=${scenarioId}` : ''
  }`;
}

/*
  LABEL INSTANCES stuff
*/
export const labelInstanceService = 'v2/labels/label-instance';
export const labelInstanceUrl = `${API}/${labelInstanceService}`;
export const findUrl = `${API}/core/find`;
export const deprecateUrl = `${API}/v2/samples/deprecate`;
const labelInstanceSelect = [
  'id',
  'labelTemplateId',
  'labelTemplateVersionId',
  'sampleId',
  'imageId',
  'objectId',
  'objectViewId',
  'originPastActionId',
  'creatorId',
  'createdAt',
  'labelText',
  'labelValue',
  'rawInput',
] as (keyof LabelInstance)[];
export function labelInstanceGetBody(ids: LabelInstanceParams, jobId: string): Record<string, any> {
  const obj = {
    service: labelInstanceService,
    query: {
      $select: labelInstanceSelect,
      isDeprecated: false,
      jobId,
    },
  } as Record<string, any>;

  if (ids.samples.length) {
    obj.query.sampleId = { $in: ids.samples };
  }
  if (ids.templates.length) {
    obj.query.labelTemplateId = { $in: ids.templates };
  }
  if (ids.objectViews.length) {
    obj.query.objectViewId = { $in: ids.objectViews };
  }

  return obj;
}
export function deprecateBody(labelId: string, jobId: string): DeprecationBody {
  return {
    labelInstance: {
      labelInstanceId: labelId,
      comments: 'Removed by labeling tool',
    },
    jobId: jobId,
    force: true,
  };
}

/*
  OBJECT VIEWS stuff
*/
// export function objectViewsUrl(id: string): string {
//   return `${API}/samples/object-view/${id}`;
// }
// export function objectsUrl(id: string): string {
//   return `${API}/samples/object/${id}`;
// }
