/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file jobSlice.ts
 * @desc Created on Fri Jun 03 2022 02:19:03
 * @copyright All rights reserved @ Inarix
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { WritableDraft } from 'immer/dist/internal';
import { JobStatus } from '../../declarations/Constant';
import { JobWithEager } from '../../declarations/Job';
import { QueriableItem } from '../../declarations/QueriableItem';
import { RootState } from '../store';
import { jobWithEagerUrl } from '../utils/queries';
import { queryWrap } from '../utils/queryWrapper';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';
import { saveInstances } from './labelInstancesSlice';
import { SCENARIO_INSTANCE_ID } from '../../Conf';

export const initialState: QueriableItem & {
  msSpent: number;
  scenarioInstanceId: number;
} = {
  status: 'unfetched',
  scenarioInstanceId: SCENARIO_INSTANCE_ID,
  msSpent: 0,
};

// selectors
export const selectJobStatus = (state: RootState): typeof state.job.status => state.job.status;
export const selectJobErr = (state: RootState): typeof state.job.error => state.job.error;
export const selectMsSpent = (state: RootState): typeof state.job.msSpent => state.job.msSpent;

// thunks
export const fetchJob = createAsyncThunk('job/details', async (_: null | undefined, store) => {
  const { state, authHead } = thunkInit(store);
  const id = state.jobs.current;
  if (!id) {
    throw new Error('No selected job');
  }

  return (await queryWrap(axios.get(jobWithEagerUrl(id), authHead))).data as JobWithEager;
});
// TODO I REALLY don't like having slices bleeding into eachother
export const saveJobPage = createAsyncThunk(
  'job/save',
  async (usePrevious: boolean | undefined, store) => {
    const jobs = (store.getState() as RootState).jobs;
    if (jobs.current && JobStatus.current === jobs.data[jobs.current]?.statusId) {
      // TODO add qdrant save in here probably
      await Promise.all([store.dispatch(saveInstances(usePrevious))]);
    }
  },
);

// slice
const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearJob: (): typeof initialState => {
      return initialState;
    },
    saveCustomScenarioInstance: (state, action: PayloadAction<number | null | undefined>): void => {
      if (action.payload) {
        state.scenarioInstanceId = action.payload;
      }
    },
    addElapsedTime: (state, action: PayloadAction<number>): void => {
      state.msSpent += action.payload || 1;
    },
  },
  extraReducers(builder) {
    // saveJobPage() isn't configured here intentionally, we don't want it to change the .status
    addBaseThunkCases(builder, [fetchJob], (state: WritableDraft<typeof initialState>) => {
      state.status = 'fulfilled';
    });
  },
});

// actions
export const { addElapsedTime, clearJob, saveCustomScenarioInstance } = jobSlice.actions;
export default jobSlice.reducer;
