/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file thunks.ts
 * @desc Created on Tue May 24 2022 18:50:53
 * @copyright All rights reserved @ Inarix
 */
import { ActionReducerMapBuilder, AsyncThunk, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { QueriableItem } from '../../declarations/QueriableItem';
import { WritableDraft } from 'immer/dist/internal';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { RootState } from '../store';
import { AxiosRequestConfig } from 'axios';
import { authHeader } from './queries';
import { checkToken } from './tokens';

/**
 * It adds the pending, rejected, (and fulfilled) cases for a list of thunks
 * @param builder - ActionReducerMapBuilder<QueriableItem>
 * @param {AsyncThunk<any, any, {}>[]} thunkActions - An array of thunk actions that you want to add
 * cases for.
 * @param [fulfilledReducer] - A function that will be called when the thunk is fulfilled.
 */
export function addBaseThunkCases(
  builder: ActionReducerMapBuilder<QueriableItem>,
  thunkActions: AsyncThunk<any, any, {}>[],
  fulfilledReducer?: (state: WritableDraft<any>, action: PayloadAction<any>) => void,
): void {
  thunkActions.forEach((action) => {
    builder
      .addCase(action.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(action.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      });

    if (fulfilledReducer) {
      builder.addCase(action.fulfilled, fulfilledReducer);
    }
  });
}

/**
 * It returns an object with the current state and an auth header,
 * after checking if the session token is still valid.
 * @param store - BaseThunkAPI<unknown, any, Dispatch, unknown>
 * @returns An object with two properties: state and authHead.
 */
export function thunkInit(store: BaseThunkAPI<unknown, any, Dispatch, unknown>): {
  state: RootState;
  authHead: AxiosRequestConfig;
} {
  const state = store.getState() as RootState;
  return {
    state,
    authHead: authHeader(checkToken(state)),
  };
}
