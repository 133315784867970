/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file store.ts
 * @desc Created on Tue May 24 2022 18:52:48
 * @copyright All rights reserved @ Inarix
 */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import listenerMiddleware from './listener';
import docSlice from './slices/docSlice';
import imagesSlice from './slices/imagesSlice';
import jobSlice from './slices/jobSlice';
import jobsSlice from './slices/jobsSlice';
import labelInstancesSlice from './slices/labelInstancesSlice';
import labelTemplatesSlice from './slices/labelTemplatesSlice';
import objectViewsSlice from './slices/objectViewsSlice';
import pastActionSlice from './slices/pastActionSlice';
import uiSlice from './slices/uiSlice';
import userSlice from './slices/userSlice';

export const reducers = combineReducers({
  user: userSlice,
  jobs: jobsSlice,
  job: jobSlice,
  images: imagesSlice,
  labelTemplates: labelTemplatesSlice,
  labelInstances: labelInstancesSlice,
  objectViews: objectViewsSlice,
  pastAction: pastActionSlice,
  ui: uiSlice,
  doc: docSlice,
});

const appStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export default appStore;
