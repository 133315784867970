/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Timer.tsx
 * @desc Created on Sat Feb 25 2023 21:33:09
 * @copyright All rights reserved @ Inarix
 */

import { FC, useEffect } from 'react';
import { JobStatus } from '../../declarations/Constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addElapsedTime, selectMsSpent } from '../../redux/slices/jobSlice';
import { selectCurrentJob } from '../../redux/slices/jobsSlice';
import { formatHour } from '../../redux/utils/date';
import { Clock } from '../utils/clock';

const Timer: FC = () => {
  const dispatch = useAppDispatch();
  const msSpent = useAppSelector(selectMsSpent),
    jobIsRunning = useAppSelector(selectCurrentJob)?.statusId == JobStatus.current;

  useEffect(() => {
    const id = jobIsRunning
      ? setTimeout(() => {
          if (Clock.instance.running) {
            const delta = Clock.instance.getDelta() || 1;
            dispatch(addElapsedTime(delta));
          }
        }, 1000)
      : -1;

    return (): void => clearTimeout(id);
  });

  return jobIsRunning ? <span>Time spent: {formatHour(msSpent)}</span> : null;
};

export default Timer;
