/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file LabelsTab.tsx
 * @desc Created on Sat Sep 24 2022 01:56:32
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { DerivedImage } from '../../declarations/Image';
import { JobTabNames } from '../../declarations/UiProps';
import { LabelInstance } from '../../declarations/labelInstance';
import { useAppSelector } from '../../redux/hooks';
import {
  fetchSignedUrl,
  invertSelected,
  selectCurrentId,
  selectHighlighted,
  selectImagesByPage,
  setModalImage,
  toggleAllPageOn,
  toggleSelected,
} from '../../redux/slices/imagesSlice';
import { selectColorMap, selectColors } from '../../redux/slices/labelTemplatesSlice';
import {
  selectJobTab,
  selectLabelInstanceFocus,
  setLabelInstanceFocus,
} from '../../redux/slices/uiSlice';
import { selectLabelInstances } from '../../redux/slices/labelInstancesSlice';
import { formatDate } from '../../redux/utils/date';
import { Box, ButtonGroup, List } from '@mui/material';
import CopiableText from '../atomic/CopiableText';
import ReduxButton from '../atomic/io/ReduxButton';
import { Label, LabelButton } from '../atomic/io/LabelButton';
import listItemFactory from '../utils/listItemFactory';

function searchInstance(
  labels: Record<string, LabelInstance>,
  value?: string,
): LabelInstance | undefined {
  if (!value) {
    return;
  }
  for (const key in labels) {
    if (value == labels[key]?.labelText) {
      return labels[key];
    }
  }
}

const LabelsTab: FC = () => {
  const images = useAppSelector(selectImagesByPage),
    isImageListMode = !useAppSelector(selectCurrentId),
    selection = useAppSelector(selectHighlighted),
    colorMap = useAppSelector(selectColorMap),
    colors = useAppSelector(selectColors),
    tab = JobTabNames[useAppSelector(selectJobTab)] as number,
    firstImage = selection.length ? images[selection[0]] : null,
    labelInstances = useAppSelector((state) => selectLabelInstances(state, firstImage?.id)),
    labelInstanceFocus = useAppSelector(selectLabelInstanceFocus);

  if (firstImage) {
    const instancesOrTempLabels = Object.keys(firstImage.labels).map((label) => {
      if (firstImage.labels[label] && undefined !== colorMap[label]) {
        const instance = labelInstances ? searchInstance(labelInstances, label) : null;
        return instance ? (
          <LabelButton
            key={'label_info_' + instance.id}
            action={setLabelInstanceFocus}
            // disabed type-checking here because of LabelValue expected type :(
            value={instance as any}
            shortcut=""
            color={colors[colorMap[label] as number]}
            mui={{
              variant: labelInstanceFocus?.id == instance.id ? 'contained' : 'outlined',
            }}
          >
            {label}
          </LabelButton>
        ) : (
          <Label key={'label_info_' + label} color={colors[colorMap[label] as number]}>
            {label}
          </Label>
        );
      } else {
        return null;
      }
    });

    return (
      <div hidden={JobTabNames.labels !== tab}>
        <h4>
          {selection.length} item{1 !== selection.length && 's'} selected
        </h4>
        <ButtonGroup fullWidth>
          <ReduxButton mui={{ variant: 'outlined', size: 'small' }} action={toggleSelected}>
            Unselect
          </ReduxButton>
          {isImageListMode ? (
            <ReduxButton mui={{ variant: 'outlined', size: 'small' }} action={invertSelected}>
              Invert selection
            </ReduxButton>
          ) : null}
        </ButtonGroup>
        {1 === selection.length && (
          <div>
            <h4>Information</h4>
            <List dense disablePadding sx={{ color: 'text.secondary' }}>
              {listItemFactory('Creation', firstImage.createdAt)}
              {listItemFactory('Image', <CopiableText content={firstImage.imageId} />)}
              {(firstImage as DerivedImage).objectId &&
                listItemFactory(
                  'Grain',
                  <CopiableText content={(firstImage as DerivedImage).objectId as string} />,
                )}
              {(firstImage as DerivedImage).objectViewId &&
                listItemFactory(
                  'Viewpoint',
                  <CopiableText content={(firstImage as DerivedImage).objectViewId as string} />,
                )}
            </List>
            {instancesOrTempLabels.length ? (
              <Box>
                <h4>Labels</h4>
                <div id="existing_labels">{instancesOrTempLabels}</div>
                {labelInstanceFocus ? (
                  <List dense disablePadding sx={{ marginTop: '20px', color: 'text.secondary' }}>
                    {listItemFactory('Creation', formatDate(labelInstanceFocus.createdAt))}
                    {listItemFactory(
                      'Creator',
                      <CopiableText content={labelInstanceFocus.creatorId} />,
                    )}
                  </List>
                ) : null}
              </Box>
            ) : null}
            {(firstImage as DerivedImage).origin && (
              <ReduxButton
                action={[setModalImage, fetchSignedUrl]}
                value={(firstImage as DerivedImage).origin}
                mui={{ sx: { width: '100%', marginTop: '20px' } }}
              >
                Show original image
              </ReduxButton>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <p hidden={JobTabNames.labels !== tab}>
        <ReduxButton action={toggleAllPageOn} mui={{ sx: { width: '100%' } }}>
          Select all in view
        </ReduxButton>
      </p>
    );
  }
};

export default LabelsTab;
