/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file clock.ts
 * @desc Created on Sat Feb 25 2023 21:10:43
 * @copyright All rights reserved @ Inarix
 *
 * Shamelessly copied and lightly adapted from mrdoob's three.js, under MIT licence.
 * https://threejs.org
 */

export class Clock {
  private autoStart = true;
  private oldTime = 0;

  public running = false;

  constructor(autoStart = true) {
    if (Clock._inst) {
      throw new Error('This is a singleton');
    }

    this.autoStart = autoStart;
  }

  private static _inst: Clock;
  static get instance(): Clock {
    return this._inst || (this._inst = new Clock());
  }

  /*
   * Starts clock. Also sets the .oldTime to the current time,
   * sets .running to true.
   */
  start(): void {
    this.oldTime = Date.now();
    this.running = true;
  }

  /*
   * Stops clock
   */
  stop(): void {
    this.running = false;
    this.autoStart = false;
  }

  /*
   * Get the miliseconds passed since the time .oldTime was set and sets .oldTime to the current time.
   * If .autoStart is true and the clock is not running, also starts the clock.
   */
  getDelta(): number {
    let diff = 0;

    if (this.autoStart && !this.running) {
      this.start();
      return 0;
    }

    if (this.running) {
      const newTime = Date.now();

      diff = newTime - this.oldTime;
      this.oldTime = newTime;
    }

    return diff;
  }
}
