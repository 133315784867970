/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file listener.ts
 * @desc Created on Wed May 25 2022 11:31:27
 * @copyright All rights reserved @ Inarix
 */
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from './store';
import { addListener, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { LOCAL_STORAGE_KEY_JOB, LOCAL_STORAGE_KEY_PAGE, LOCAL_STORAGE_KEY_TOKEN } from '../Conf';
import { parseJob } from '../declarations/Job';
import {
  changeViewMode,
  clearImages,
  fetchSignedUrls,
  initComplete,
  invertSelected,
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
  setImageLabels,
  setImagePage,
  setImages,
  toggleAllPageOn,
  toggleSelected,
} from './slices/imagesSlice';
import { clearJobs, fetchJobs, jobSelected, patchCurrentJob } from './slices/jobsSlice';
import { connectUser, logout, refreshUser } from './slices/userSlice';
import {
  addElapsedTime,
  clearJob,
  fetchJob,
  saveCustomScenarioInstance,
  saveJobPage,
} from './slices/jobSlice';
import { addReadonly, clearTemplates, fetchLabelTemplates } from './slices/labelTemplatesSlice';
import { clearInstances, fetchInstances, saveInstances } from './slices/labelInstancesSlice';
import {
  clearPastAction,
  createPastAction,
  endPastAction,
  setPastAction,
} from './slices/pastActionSlice';
import { initObjViews } from './slices/objectViewsSlice';
import {
  openSnack,
  resetUI,
  setJobTab,
  setLabelInstanceFocus,
  setModal,
  setZoomCheck,
} from './slices/uiSlice';
import { clearDoc, setDocFile } from './slices/docSlice';
import { JobStatus } from '../declarations/Constant';
import { LabelInstance } from '../declarations/labelInstance';

/**
 * listenerMiddleware: a middleware that performs data passing from one slice to the other,
 * or runs any non-redux related side effects (logging, localStorage, etc).
 * It is used as a pivot point between slices when eagerJoins are done in queries
 */

const listenerMiddleware = createListenerMiddleware();
let refreshInterval = 0;

// global logger to trace workflow
// listenerMiddleware.startListening({
//   predicate: () => true,
//   effect: (action) => {
//     action.type && !/\/pending/.test(action.type) && console.log(action.type);
//   },
// });

// global session timeout : log out the user
listenerMiddleware.startListening({
  predicate: (action) => /\/rejected$/.test(action.type),
  effect: (action, listenerAPI) => {
    if (/401/.test(action.error.message) || 'Session expired' === action.error.message) {
      listenerAPI.dispatch(logout('Session expired, please log back in'));
    }
  },
});

// user logs out, delete their token
listenerMiddleware.startListening({
  actionCreator: logout,
  effect: (_, listenerAPI) => {
    window.clearInterval(refreshInterval);
    refreshInterval = 0;
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
    listenerAPI.dispatch(clearJob());
    listenerAPI.dispatch(clearJobs());
  },
});
// user logged in: fetch their assigned jobs
listenerMiddleware.startListening({
  matcher: isAnyOf(connectUser.fulfilled, refreshUser.fulfilled),
  effect: async (action, listenerAPI) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, action.payload.token);
    if (!refreshInterval) {
      // refresh the user token every 10mn
      refreshInterval = window.setInterval(() => {
        listenerAPI.dispatch(refreshUser(localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) || ''));
      }, 1000 * 10 * 60);
      await listenerAPI.dispatch(fetchJobs(false));
    }
  },
});

// jobs list is fetched, and we have a current job saved: set it!
listenerMiddleware.startListening({
  actionCreator: fetchJobs.fulfilled,
  effect: (_, listenerAPI) => {
    const jobId = localStorage.getItem(LOCAL_STORAGE_KEY_JOB);
    listenerAPI.dispatch(setZoomCheck(false));
    if (jobId) {
      listenerAPI.dispatch(jobSelected(jobId));
    }
  },
});
// current job have been changed: fetch its details
listenerMiddleware.startListening({
  predicate: (action, _, prevState) =>
    action.type === jobSelected.type &&
    action.payload &&
    action.payload !== (prevState as RootState).jobs.current &&
    (prevState as RootState).jobs.data[action.payload],
  effect: async (action, listenerAPI) => {
    listenerAPI.unsubscribe();
    const prevState = listenerAPI.getOriginalState() as RootState;
    if (prevState.jobs.current) {
      if (prevState.jobs.data[prevState.jobs.current]?.statusId == JobStatus.current) {
        await listenerAPI.dispatch(
          patchCurrentJob({
            id: prevState.jobs.current,
            activityDurationSec: Math.round(prevState.job.msSpent / 1000),
          }),
        );
      }
      listenerAPI.dispatch(clearJob());
    }
    localStorage.setItem(LOCAL_STORAGE_KEY_JOB, action.payload);
    await listenerAPI.dispatch(fetchJob());
    listenerAPI.subscribe();
  },
});
// job details have been fetched: save it in the relevant slices
listenerMiddleware.startListening({
  actionCreator: fetchJob.fulfilled,
  effect: async (action, listenerAPI) => {
    listenerAPI.unsubscribe();
    if (action.payload.pastActionId && action.payload.pastAction) {
      listenerAPI.dispatch(setPastAction(action.payload.pastAction));
    }
    if (action.payload.docFileLocationId && action.payload.docFileLocation) {
      listenerAPI.dispatch(setDocFile(action.payload.docFileLocation));
    }

    await listenerAPI.dispatch(
      fetchLabelTemplates({
        ids: action.payload.labelTemplates as string[],
        scenarioId: action.payload.scenarioId,
      }),
    );

    const { samples, images, objViews } = parseJob(action.payload);
    listenerAPI.dispatch(setImages(images.serialize));
    listenerAPI.dispatch(initObjViews(objViews));
    listenerAPI.dispatch(
      saveCustomScenarioInstance(action.payload.scenario?.lastScenarioInstanceId),
    );

    const labelInstances = (await listenerAPI.dispatch(
      fetchInstances({
        samples: samples,
        objectViews: Object.keys(objViews),
        templates: action.payload.labelTemplates || [],
      }),
    )) as unknown as { payload: { idMap: Record<string, string>; labels: LabelInstance[] } };

    if (labelInstances.payload.labels.length) {
      listenerAPI.dispatch(addReadonly(labelInstances.payload.labels));
    }
    listenerAPI.dispatch(initComplete());

    if (JobStatus.open === action.payload.statusId) {
      listenerAPI.dispatch(
        openSnack({
          message: 'Job is read only for now. Click on "Start" to get to it!',
          type: 'info',
        }),
      );
    } else if (JobStatus.current === action.payload.statusId) {
      listenerAPI.dispatch(
        addElapsedTime(
          action.payload.activityDurationSec ? action.payload.activityDurationSec * 1000 : 1,
        ),
      );
    } else {
      listenerAPI.dispatch(openSnack({ message: 'Job is read only for now', type: 'info' }));
    }

    listenerAPI.subscribe();
  },
});
// current job have been quit: clear its details
listenerMiddleware.startListening({
  actionCreator: clearJob,
  effect: async (_, listenerAPI) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_JOB);
    localStorage.removeItem(LOCAL_STORAGE_KEY_PAGE);
    listenerAPI.dispatch(resetUI());
    listenerAPI.dispatch(clearDoc());
    listenerAPI.dispatch(clearPastAction());
    listenerAPI.dispatch(clearImages());
    listenerAPI.dispatch(clearTemplates());
    listenerAPI.dispatch(clearInstances());
  },
});
// job page have been saved: notify the user
listenerMiddleware.startListening({
  actionCreator: saveJobPage.fulfilled,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(
      openSnack({
        message: 'Your progress have been saved!',
        type: 'success',
      }),
    );
  },
});

// past action have been created: save its id
listenerMiddleware.startListening({
  actionCreator: createPastAction.fulfilled,
  effect: async (action, listenerAPI) => {
    if (action.payload) {
      await listenerAPI.dispatch(
        patchCurrentJob({
          assignedUser: action.payload.creatorId,
          assignedAt: new Date().toISOString(),
          statusId: JobStatus.current,
          pastActionId: action.payload.id,
        }),
      );
      listenerAPI.dispatch(addElapsedTime(1));
    }
  },
});
// past action have been closed: update job status
listenerMiddleware.startListening({
  actionCreator: endPastAction.fulfilled,
  effect: async (action, listenerAPI) => {
    if (action.payload) {
      const state = listenerAPI.getState() as RootState;
      await listenerAPI.dispatch(
        patchCurrentJob({
          statusId: JobStatus.closed,
          activityDurationSec: Math.round(state.job.msSpent / 1000),
        }),
      );
      listenerAPI.dispatch(setModal(''));
      listenerAPI.dispatch(
        openSnack({ type: 'info', message: 'Job has been closed and is now read only.' }),
      );
    }
  },
});

// modal is being opened: gotta deselect images
listenerMiddleware.startListening({
  actionCreator: setModal,
  effect: (action, listenerAPI) => {
    if (action.payload) {
      listenerAPI.dispatch(toggleSelected());
    }
  },
});

// images have been set: check if we had a page saved and apply it
listenerMiddleware.startListening({
  actionCreator: setImages,
  effect: (_, listenerAPI) => {
    const page = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_PAGE) || '0');
    listenerAPI.dispatch(setImagePage(page));
  },
});
// page have been changed: fetch signed urls for the new page & update label instances
listenerMiddleware.startListening({
  actionCreator: setImagePage,
  effect: async (action, listenerAPI) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_PAGE, `${action.payload}`);
    await listenerAPI.dispatch(fetchSignedUrls());
    await listenerAPI.dispatch(saveJobPage(true));

    const state = listenerAPI.getState() as RootState;
    const ms = state.job.msSpent;
    const prevMs = (state.jobs.data[state.jobs.current]?.activityDurationSec || 0) * 1000;
    if (ms - prevMs > 1000) {
      await listenerAPI.dispatch(
        patchCurrentJob({
          activityDurationSec: Math.round(ms / 1000),
        }),
      );
    }
  },
});
// image selection changed: swap between UI tabs, and maybe clear label instance focus
listenerMiddleware.startListening({
  matcher: isAnyOf(
    toggleSelected,
    changeViewMode,
    toggleAllPageOn,
    invertSelected,
    setImagePage,
    moveLeft,
    moveRight,
    moveUp,
    moveDown,
  ),
  effect: (_, listenerAPI) => {
    const currImages = (listenerAPI.getState() as RootState).images;
    if (currImages.selectedLocalIndexes.length) {
      const prevImages = (listenerAPI.getOriginalState() as RootState).images;
      if (
        prevImages.bigImage != currImages.bigImage ||
        prevImages.selectedLocalIndexes[0] != currImages.selectedLocalIndexes[0]
      ) {
        listenerAPI.dispatch(setLabelInstanceFocus());
      }
      listenerAPI.dispatch(setJobTab('labels'));
    } else {
      listenerAPI.dispatch(setJobTab('job'));
      listenerAPI.dispatch(setLabelInstanceFocus());
    }
  },
});

// label instances received from database: update label values on images
listenerMiddleware.startListening({
  matcher: isAnyOf(saveInstances.fulfilled, fetchInstances.fulfilled),
  effect: (action, listenerAPI) => {
    listenerAPI.dispatch(setImageLabels(action.payload.inserted || action.payload.labels));
  },
});

// static typings for compiler
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;
export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

export default listenerMiddleware;
