/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file colorPicker.ts
 * @desc Created on Wed Jun 08 2022 10:52:31
 * @copyright All rights reserved @ Inarix
 */

import turboColorMap from './turboColorMap';

// tuple issue
// function toString(rgb: [number, number, number]): string {
/**
 * It takes an array of three numbers, each between 0 and 1, and returns a string that represents the
 * RGB value of those numbers
 * @param {number[]} rgb - The RGB color to convert to HSL.
 * @returns A string of the rgb values
 */
function toString(rgb: number[]): string {
  return `rgb(${rgb[0] * 255}, ${rgb[1] * 255}, ${rgb[2] * 255})`;
}

// this math is kinda random and will probably have to be improved
// for now, we're adding 40 points (over 255) to every value of the color
/**
 * It takes an array of numbers, and returns an array of numbers
 * @param {number[]} rgb - number[]
 * @returns A new array with each element increased by at most 0.15686.
 */
function lighten(rgb: number[]): number[] {
  return rgb.map((color) => Math.min(color + 0.15686, 1));
}

/**
 * It takes a number, and returns an array of arrays of strings
 * @param {number} length - number - the number of colors you want to generate
 * @returns An array of arrays of strings.
 */
export function selectTurboColors(length: number): string[][] {
  const step = Math.floor(turboColorMap.length / length);
  const results: string[][] = [];

  let i = Math.floor(step * 0.5);
  while (i < turboColorMap.length) {
    const color = turboColorMap[i];
    results.push([toString(color), toString(lighten(color))]);
    i += step;
  }

  return results;
}
