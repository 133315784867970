/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file DownloadLink.tsx
 * @desc Created on Fri Sep 30 2022 18:53:48
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchDoc, selectDocData } from '../../redux/slices/docSlice';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Link } from '@mui/material';

const DownloadLink: FC = () => {
  const dispatch = useAppDispatch();
  const doc = useAppSelector(selectDocData);
  const fetch: MouseEventHandler = async (e) => {
    e.preventDefault();
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
    const element = document.getElementById('dl_source') as HTMLLinkElement;
    const signed = await dispatch(fetchDoc());
    // (element as any).download = signed.payload.split('?')[0].split('/').pop();
    element.href = signed.payload;
    element.click();
  };

  return doc.id ? (
    <p>
      <Link underline="hover" onClick={fetch}>
        <FilePresentIcon
          sx={{
            mr: 1,
            lineHeight: 16,
            display: 'inline-block',
            verticalAlign: 'text-bottom',
          }}
        />
        Download job documentation
      </Link>
      <Link hidden id="dl_source" download />
    </p>
  ) : null;
};

export default DownloadLink;
