/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file SnackContainer.tsx
 * @desc Created on Fri Sep 23 2022 16:42:59
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hideSnack, selectSnack } from '../../redux/slices/uiSlice';
import { Alert, Snackbar } from '@mui/material';

const SnackContainer: FC = () => {
  const snackContent = useAppSelector(selectSnack);
  const dispatch = useAppDispatch();
  const handleHide = (): void => {
    dispatch(hideSnack());
  };

  return (
    <Snackbar
      open={snackContent.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleHide}
    >
      <Alert severity={snackContent.severity}>{snackContent.message}</Alert>
    </Snackbar>
  );
};

export default SnackContainer;
