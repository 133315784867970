/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file LabelButton.tsx
 * @desc Created on Wed Jun 08 2022 15:56:16
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { ButtonParams } from '../../../declarations/DelayedInputParams';
import { TemplateValue } from '../../../declarations/LabelTemplate';
import ButtonWithKey from './ButtonWithKey';

const ShortcutIcon: FC<{ color: string; shortcut: string }> = (props) => {
  return (
    <span className="shortcut_btn" style={{ backgroundColor: props.color }}>
      <span className="shortcut_txt">{props.shortcut.toUpperCase()}</span>
    </span>
  );
};

export const LabelButton: FC<ButtonParams & TemplateValue> = (props) => {
  return (
    <ButtonWithKey
      {...props}
      special="none"
      mui={{
        variant: props.mui?.variant || 'outlined',
        className: props.mui?.className ? props.mui.className + ' label_btn' : 'label_btn',
        sx: {
          borderColor: props.color[1],
          color: props.color[0],
        },
        startIcon: props.shortcut ? (
          <ShortcutIcon color={props.color[0]} shortcut={props.shortcut} />
        ) : null,
      }}
    >
      {props.bypassValueRender ? null : props.children ? props.children : props.value}
    </ButtonWithKey>
  );
};

export const Label: FC<{ color: string[] }> = (props) => {
  return (
    <span
      className="label_btn"
      style={{
        borderColor: props.color[1],
        color: props.color[0],
      }}
    >
      {props.children}
    </span>
  );
};
