/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Spinner.tsx
 * @desc Created on Fri Jul 12 2024 22:40:02
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';

const Spinner: FC = () => {
  return <div className="spinner"></div>;
};

export default Spinner;
