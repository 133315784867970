/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file labelTemplatesSlice.ts
 * @desc Created on Tue May 24 2022 18:44:49
 * @copyright All rights reserved @ Inarix
 */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../store';
import {
  SimpleLabelTemplate,
  TemplateValue,
  VirtualLabelTemplate,
} from '../../declarations/LabelTemplate';
import { QueriableItem } from '../../declarations/QueriableItem';
import { selectTurboColors } from '../utils/colorPicker';
import IndexedMap from '../utils/IndexedMap';
import { labelTemplateUrl } from '../utils/queries';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';
import { LabelInstance } from '../../declarations/labelInstance';
import { queryWrap } from '../utils/queryWrapper';
import alphabet from '../utils/alphabet';
import { simpleLabelTemplateFactory } from '../utils/labelTemplate';

export const initialState: {
  colors: string[][];
  colorMap: Record<string, number>;
  templateMap: Record<string, number>;
  // CONVENTION: if a freeText tempalte exists, it is at the end of the array
  templateData: SimpleLabelTemplate[];
  classesData: string[];
  extraValues: Record<string, boolean>;
  hasFreeText: boolean;
} & QueriableItem = {
  status: 'unfetched',
  classesData: [],
  colorMap: {},
  templateMap: {},
  templateData: [],
  colors: [],
  extraValues: {},
  hasFreeText: false,
};

// selectors
const _selectClasses = (state: RootState): typeof state.labelTemplates.classesData =>
  state.labelTemplates.classesData;
const _selectExtras = (state: RootState): typeof state.labelTemplates.extraValues =>
  state.labelTemplates.extraValues;
const _selectFullData = (state: RootState): typeof state.labelTemplates.templateData =>
  state.labelTemplates.templateData;
const _selectDataMap = (state: RootState): typeof state.labelTemplates.templateMap =>
  state.labelTemplates.templateMap;
export const selectHasFreetext = (state: RootState): typeof state.labelTemplates.hasFreeText =>
  state.labelTemplates.hasFreeText;
export const selectColors = (state: RootState): typeof state.labelTemplates.colors =>
  state.labelTemplates.colors;
export const selectColorMap = (state: RootState): typeof state.labelTemplates.colorMap =>
  state.labelTemplates.colorMap;
export const selectTemplatesStatus = (state: RootState): typeof state.labelTemplates.status =>
  state.labelTemplates.status;
export const selectTemplatesError = (state: RootState): typeof state.labelTemplates.error =>
  state.labelTemplates.error;
export const selectLabelValues = createSelector(
  _selectClasses,
  _selectFullData,
  _selectDataMap,
  _selectExtras,
  selectColors,
  (classes, tpls, tplMap, extraDict, colors) => {
    const shortcuts: string[][] = [];
    if (classes.length) {
      const hotkeys = tpls.map((tpl) => tpl.config?.substitutionDict?.$hotkeys || {});
      const checkList = new IndexedMap<boolean>().from(alphabet, false);
      classes.forEach((val) => {
        // TODO it's the hotkey[idx] that is undefined, maybe find a way to have it defined by the reducers?
        let key =
          hotkeys[tplMap[val] as number] && hotkeys[tplMap[val] as number][val]?.toLowerCase();

        // do we have a hotkey that's in the valid alphabet, and not used yet?
        if (undefined !== key && checkList.has(key) && !checkList.get(key)) {
          shortcuts.push([key, val]);
          checkList.ref(key, true);
        } else {
          // remove diacritics
          const normalizedVal = val
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          let idx = 0;
          // search for an available & valid letter in the word
          while (
            checkList.get(normalizedVal.charAt(idx)) &&
            !isNaN(normalizedVal.charCodeAt(idx))
          ) {
            ++idx;
          }
          key = normalizedVal.charAt(idx);
          // did we go past the end of the word, meaning, are all the letters in the word taken?
          // in that case, take any letter that is sill available
          if (!key || !checkList.has(key)) {
            key = checkList.findKey((isTaken) => isTaken == false) as string;
          }

          shortcuts.push([key, val]);
          checkList.ref(key, true);
        }
      });
    }

    const result: TemplateValue[] = shortcuts.map((tuple, idx) => ({
      shortcut: tuple[0],
      value: tuple[1],
      color: colors[idx],
    }));

    let index = classes.length;
    for (const key in extraDict) {
      result.push({
        shortcut: '',
        value: key,
        color: colors[index++],
      });
    }
    return result;
  },
);

// thunks
export const fetchLabelTemplates = createAsyncThunk(
  'labelTemplates/fetch',
  async (input: { ids: string[]; scenarioId?: number | undefined }, store) => {
    const tplIds = input?.ids;
    if (!tplIds?.length) {
      return;
    }

    const { authHead } = thunkInit(store);
    return (
      await Promise.all(
        tplIds.map((id) => queryWrap(axios.get(labelTemplateUrl(id, input.scenarioId), authHead))),
      )
    ).map(
      // Note: the order of responses is identical as the first array. See example @ EoF
      (res: AxiosResponse<VirtualLabelTemplate>, i) => ({ id: tplIds[i], ...res.data }),
    );
  },
);

// slice
const labelTemplatesSlice = createSlice({
  name: 'labelTemplates',
  initialState,
  reducers: {
    clearTemplates: (): typeof initialState => {
      return initialState;
    },
    addReadonly: (state, action: PayloadAction<LabelInstance[]>): void => {
      let initCount = Object.keys(state.extraValues).length + state.classesData.length - 1;
      const initClassLength = state.classesData.length;
      const freeTextIdx = state.templateData.length - 1;
      const freeTextTpl = state.hasFreeText ? state.templateData[freeTextIdx] : null;

      // freeText is configured: gotta check what existing values have been created by it
      if (freeTextTpl) {
        action.payload.forEach(({ rawInput, labelTemplateId }) => {
          if (freeTextTpl.id == labelTemplateId) {
            if (undefined == state.colorMap[rawInput] && undefined == state.templateMap[rawInput]) {
              state.colorMap[rawInput] = state.classesData.length;
              state.templateMap[rawInput] = freeTextIdx;
              state.classesData.push(rawInput);
            }
          } else if (
            !state.extraValues[rawInput] &&
            'undefined' == typeof state.templateMap[rawInput]
          ) {
            state.extraValues[rawInput] = true;
            state.colorMap[rawInput] = ++initCount;
          }
        });
      }
      // else we consider all values as created in another job/context
      else {
        action.payload.forEach(({ rawInput }) => {
          if (!state.extraValues[rawInput] && 'undefined' == typeof state.templateMap[rawInput]) {
            state.extraValues[rawInput] = true;
            state.colorMap[rawInput] = ++initCount;
          }
        });
      }

      // no extra label instances added: let's just skip
      const length = Object.keys(state.extraValues).length;
      if (length || initClassLength != state.classesData.length) {
        state.colors = selectTurboColors(length + state.classesData.length);
      }
    },
    addFreeText: (state, action: PayloadAction<string>): void => {
      if (!state.hasFreeText || !action.payload) {
        return;
      }

      state.colorMap[action.payload] = state.classesData.length;
      state.templateMap[action.payload] = state.templateData.length - 1;
      state.classesData.push(action.payload);
      state.colors = selectTurboColors(Object.keys(state.colorMap).length);
    },
  },
  extraReducers(builder) {
    addBaseThunkCases(
      builder,
      [fetchLabelTemplates],
      (
        state: WritableDraft<typeof initialState>,
        action: PayloadAction<({ id: string } & VirtualLabelTemplate)[] | undefined>,
      ) => {
        state.status = 'fulfilled';
        let freeTextTemplate: SimpleLabelTemplate | null = null;

        if (action.payload) {
          // Test data for freetext template

          // const now = new Date().toISOString();
          // action.payload.push({
          //   id: 'templateId',
          //   labelTemplateVersionId: 'versionId',
          //   labelTemplateId: 'templateId',
          //   typeConstantId: 28,
          //   scopeConstantId: 29,
          //   generatedByLabelTemplateConfig: {
          //     id: 'configId',
          //     createdAt: now,
          //     updatedAt: now,
          //     createdBy: 'me',
          //     updatedBy: 'me',
          //     labelTemplateId: 'templateId',
          //     slug: 'some_config',
          //     userFacingNameTextId: 12,
          //   },
          //   labelInstanceMetadataSeed: { meta: true },
          //   scope: {
          //     id: 29,
          //     value: 'object_view',
          //     createdAt: now,
          //     updatedAt: now,
          //     createdBy: 'me',
          //     originTableName: 'aaa',
          //   },
          //   type: {
          //     id: 28,
          //     value: 'freetext',
          //     createdAt: now,
          //     updatedAt: now,
          //     createdBy: 'me',
          //     originTableName: 'aaa',
          //   },
          // });
          const classes: string[] = [];
          // const bounds: number[][] = [];

          // TODO warning! this works only if classes are all distinct across all the templates we're using
          action.payload.forEach((tpl) => {
            if (tpl.type?.value == 'freetext') {
              if (state.hasFreeText) {
                state.error =
                  'Duplicate freetext label templates in configuration, please refer to the job creator to fix this issue';
                state.status = 'rejected';
                return;
              }

              freeTextTemplate = simpleLabelTemplateFactory(tpl);
              state.hasFreeText = true;
            } else if (tpl.possibleClasses) {
              if (!tpl.scope || !tpl.type) {
                state.error = `Missing scope or type in label template '${tpl.id}'`;
                state.status = 'rejected';
                return;
              }

              const templateIdx = state.templateData.push(simpleLabelTemplateFactory(tpl)) - 1;
              const substitution = tpl.generatedByLabelTemplateConfig.substitutionDict || {};
              const shortcuts = substitution.$hotkeys || {};

              // a dictionary with real classes as keys, to help with filtering
              const classesDict = tpl.possibleClasses.reduce((result, current) => {
                result[current] = true;
                return result;
              }, {} as Record<string, boolean>);

              // double-checking if userFacing classes actually match to a real one, and filter accordingly
              const userFacingClasses = Object.keys(substitution).filter(
                (key) => classesDict[substitution[key]],
              );

              // finally, check if configured shortcuts match with at least one configured class
              const filteredClasses = userFacingClasses.length
                ? userFacingClasses
                : tpl.possibleClasses;
              const shortcutsAreIrrelevant =
                !Object.keys(shortcuts).length || filteredClasses.every((val) => !shortcuts[val]);

              // save classes, and filter them if shortcuts are okay
              filteredClasses.forEach((value) => {
                if (shortcutsAreIrrelevant || shortcuts[value]) {
                  state.colorMap[value] = classes.length;
                  classes.push(value);
                  state.templateMap[value] = templateIdx;
                }
              });
            }

            // else if (tpl.valueBounds) {
            //   bounds.push(lastVersion.valueBounds);
            // }
          });

          state.colors = selectTurboColors(classes.length);
          state.classesData = classes;

          // CONVENTION: we always put the freetext template at the end
          if (freeTextTemplate) {
            // templateMap will be updated when adding label values
            state.templateData.push(freeTextTemplate);
          }
        } else {
          state = initialState;
        }
      },
    );
  },
});

// actions
export const { addFreeText, addReadonly, clearTemplates } = labelTemplatesSlice.actions;
export default labelTemplatesSlice.reducer;

/*
// Example about comment above:
Promise.all(
  [400, 200, 3].map(
    (ms) =>
      new Promise((resolve) => {
        setTimeout(() => resolve(ms), ms);
      }),
  ),
).then((results) => {
  console.log(results); // [400, 200, 3]
});
*/
