/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ButtonWithKey.tsx
 * @desc Created on Tue May 24 2022 19:06:40
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { ButtonParams, KeyboardShortcutParams } from '../../../declarations/DelayedInputParams';
import ReduxButton from './ReduxButton';
import VoidWithKey from './VoidWithKey';

const ButtonWithKey: FC<ButtonParams & KeyboardShortcutParams> = (props) => {
  return (
    <ReduxButton {...props}>
      {props.children}
      {props.shortcut && !props.bypassKeyboard ? <VoidWithKey {...props} /> : null}
    </ReduxButton>
  );
};

export default ButtonWithKey;
