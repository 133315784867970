/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Sidebar.tsx
 * @desc Created on Mon Sep 19 2022 17:41:59
 * @copyright All rights reserved @ Inarix
 */

import { FC, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CollectionsIcon from '@mui/icons-material/Collections';
import JobsList from './JobsList';

const openWidth = 300;

const Sidebar: FC = () => {
  const [open, setOpen] = useState(true);

  return (
    <Box>
      <IconButton
        onClick={(e): void => {
          (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
          setOpen(true);
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        id="sidebar"
        sx={{ width: open ? openWidth : 0 }}
      >
        <List>
          <ListItemButton
            onClick={(e): void => {
              (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <MenuIcon />
            </ListItemIcon>
            <ListItemText primary="Grains" secondary="Working title" />
          </ListItemButton>
        </List>
        <List subheader={<ListSubheader>Photos</ListSubheader>}>
          <ListItemButton>
            <ListItemIcon>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary="Library" secondary="Coming soon" />
          </ListItemButton>
        </List>
        <JobsList width={openWidth} />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
