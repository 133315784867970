/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file MuiTheme.tsx
 * @desc Created on Tue May 24 2022 19:04:43
 * @copyright All rights reserved @ Inarix
 */
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { inarixThemeMui } from 'inarix-theme';

const darkTheme = {
  ...inarixThemeMui,
  palette: {
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: '#C0C0C0',
      disabled: '#9C9C9C',
    },
    primary: {
      main: '#4676EE',
      dark: '#1E4CBB',
      contrastText: '#E4E4E4',
    },
    secondary: {
      main: '#C595D4',
      dark: '#9F4DB6',
      contrastText: '#E4E4E4',
    },
    action: {
      active: '#A2A2A2',
      disabled: '#747474',
      selected: '#4E4E4E',
      hover: '#3D3D3D',
    },
    success: {
      main: '#7BB972',
      dark: '#508C46',
    },
    warning: {
      main: '#F2AB47',
      dark: '#E58231',
      contrastText: '#060606',
    },
    error: {
      main: '#E15241',
      dark: '#C23F38',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#201D25',
    },
    other: {
      divider: '#464646',
      outlinedBorder: '#5D5D5D',
    },
  },
} as ThemeOptions;

export const themeInarix = createTheme(inarixThemeMui);
export const darkThemeInarix = createTheme(darkTheme);
