/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file tokens.ts
 * @desc Created on Tue May 24 2022 18:51:21
 * @copyright All rights reserved @ Inarix
 */
import jwtDecode from 'jwt-decode';
import { TokenContents } from '../../declarations/User';
import { RootState } from '../store';

export function decodeLoginToken(token: string): TokenContents {
  try {
    return jwtDecode(token);
  } catch (e) {
    return {
      realmId: 0,
      orgIds: [],
      isSuperAdmin: false,
      expires: 0,
      isTPToken: false,
    };
  }
}

export function checkToken(state: RootState): string {
  const token = state.user?.data?.token;
  if (!token) {
    throw new Error('Not authenticated');
  }
  return token;
}
