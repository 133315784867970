/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file listItemFactory.ts
 * @desc Created on Tue Mar 07 2023 01:31:54
 * @copyright All rights reserved @ Inarix
 */

import { ListItem, ListItemText } from '@mui/material';

const leftProps = { fontWeight: 'bold', color: 'text.disabled' };
const rightProps = { textAlign: 'right' };

export default function listItemFactory(
  left: string | number,
  right: string | number | JSX.Element,
): JSX.Element {
  return (
    <ListItem disablePadding divider>
      <ListItemText primaryTypographyProps={leftProps} primary={left} />
      <ListItemText sx={rightProps} primary={right} />
    </ListItem>
  );
}
