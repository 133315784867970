/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file VoidWithKey.tsx
 * @desc Created on Wed Jul 06 2022 07:01:53
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { ButtonParams, KeyboardShortcutParams } from '../../../declarations/DelayedInputParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import VoidHandlerEffect from './VoidHandlerEffect';

const VoidWithKey: FC<ButtonParams & KeyboardShortcutParams> = (props) => {
  const dispatch = useAppDispatch();
  const disabled =
    props.disabled ||
    props.disabledSelectors
      ?.map((sel) => {
        const val = useAppSelector(sel);
        return !val || 'pending' === val;
      })
      .some((res) => res);
  const handler = (e: KeyboardEvent): void => {
    if (
      !disabled &&
      ('none' === props.special || e[props.special]) &&
      e.key.toLowerCase() === props.shortcut
    ) {
      e.preventDefault();
      if (Array.isArray(props.action)) {
        props.action.forEach((action) => {
          props.value !== undefined && dispatch(action(props.value));
          props.valueFn && dispatch(action((props.valueFn as any)(e)));
        });
      } else {
        if (props.valueFn) {
          props.valueFn && dispatch(props.action((props.valueFn as any)(e)));
        } else {
          dispatch(props.action(props.value));
        }
      }
    }
  };

  return <VoidHandlerEffect handler={handler} event="keydown" />;
};

export default VoidWithKey;
