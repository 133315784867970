/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file StartCloseJob.tsx
 * @desc Created on Wed Sep 14 2022 17:29:01
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { JobStatus } from '../../declarations/Constant';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentJob } from '../../redux/slices/jobsSlice';
import { createPastAction } from '../../redux/slices/pastActionSlice';
import { setModal } from '../../redux/slices/uiSlice';
import { saveJobPage } from '../../redux/slices/jobSlice';
import ReduxButton from '../atomic/io/ReduxButton';
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const StartCloseJob: FC = () => {
  const job = useAppSelector(selectCurrentJob);

  if (job) {
    if (JobStatus.open === job.statusId) {
      return (
        <ReduxButton
          action={createPastAction}
          mui={{ startIcon: <PlayArrowIcon />, sx: { width: '100%' } }}
        >
          Start job
        </ReduxButton>
      );
    } else if (JobStatus.current === job.statusId) {
      return (
        <ReduxButton
          action={[saveJobPage, setModal]}
          value={[false, 'closeJob']}
          mui={{ color: 'success', startIcon: <CheckIcon />, sx: { width: '100%' } }}
        >
          Close job
        </ReduxButton>
      );
    }
  }

  return null;
};

export default StartCloseJob;
