/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file folderIcon.tsx
 * @desc Created on Fri Sep 30 2022 14:11:35
 * @copyright All rights reserved @ Inarix
 */

import React, { ReactElement } from 'react';
import { JobStatus } from '../../declarations/Constant';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

export const FolderIcons: Record<string, ReactElement> = {
  [JobStatus.open]: <FolderOpenIcon />,
  [JobStatus.current]: <FolderIcon />,
  [JobStatus.suspended]: <FolderOutlinedIcon />,
  [JobStatus.closed]: <FolderOffIcon />,
  [JobStatus.aborted]: <FolderOffOutlinedIcon />,
};

export function getFolderIcon(id: JobStatus, props?: any): ReactElement {
  return React.cloneElement(FolderIcons[id], props);
}
