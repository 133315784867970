/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file docSlice.ts
 * @desc Created on Fri Sep 30 2022 18:17:15
 * @copyright All rights reserved @ Inarix
 */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import axios from 'axios';
import { RootState } from '../store';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';
import { FileLocation } from '../../declarations/Job';
import { QueriableItem } from '../../declarations/QueriableItem';
import { docSignedUrl } from '../utils/queries';
import { queryWrap } from '../utils/queryWrapper';

export const initialState: { data: Partial<FileLocation> } & QueriableItem = {
  status: 'unfetched',
  data: {},
};

// selectors
export const selectDocStatus = (state: RootState): typeof initialState.status => state.doc.status;
export const selectDocError = (state: RootState): typeof initialState.error => state.doc.error;
export const selectDocData = (state: RootState): typeof initialState.data => state.doc.data;

// thunks
export const fetchDoc = createAsyncThunk('doc/fetch', async (_: null | undefined, store) => {
  const { state, authHead } = thunkInit(store);
  const doc = state.doc.data;
  if (!doc.id) {
    throw new Error('No document to fetch');
  }

  return (await queryWrap(axios.get(docSignedUrl(doc as FileLocation), authHead))).data
    .signedRequest;
});

// slice
const docSlice = createSlice({
  name: 'doc',
  initialState,
  reducers: {
    clearDoc: (): typeof initialState => {
      return initialState;
    },
    setDocFile: (state, action: PayloadAction<FileLocation>): void => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    addBaseThunkCases(builder, [fetchDoc], (state: WritableDraft<typeof initialState>, action) => {
      state.status = 'fulfilled';
      state.data.signedUrl = action.payload;
    });
  },
});

export const { clearDoc, setDocFile } = docSlice.actions;
export default docSlice.reducer;
