/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ImageModeSwap.tsx
 * @desc Created on Fri Jun 17 2022 17:26:05
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { changeViewMode, selectCurrentId } from '../../redux/slices/imagesSlice';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const ImageModeSwap: FC = () => {
  const dispatch = useAppDispatch();
  const hasBig = !!useAppSelector(selectCurrentId);
  const handler: MouseEventHandler = (e) => {
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
    dispatch(changeViewMode('true' === (e.currentTarget as HTMLInputElement).value));
  };
  return (
    <ToggleButtonGroup value={hasBig} exclusive>
      <ToggleButton value={false} size="small" onClick={handler}>
        <ViewModuleIcon />
      </ToggleButton>
      <ToggleButton value={true} size="small" onClick={handler}>
        <ViewSidebarIcon sx={{ transform: 'rotate(180deg)' }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ImageModeSwap;
