/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file TopRight.tsx
 * @desc Created on Thu Mar 02 2023 18:14:10
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import ActivityDetector from '../atomic/io/ActivityDetector';
import Logout from '../atomic/io/Logout';
import Timer from './Timer';

const TopRight: FC = () => {
  return (
    <div id="top_right">
      <Timer />
      <ActivityDetector />
      <Logout />
    </div>
  );
};

export default TopRight;
