/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file IndexedMap.ts
 * @desc Created on Tue May 31 2022 17:40:35
 * @copyright All rights reserved @ Inarix
 */

/**
 * A dictionary of items of type T indexed on keys of type K.
 * It keeps track of the order of the items
 */
export default class IndexedMap<T, K extends string | number = string> {
  protected _order: K[] = [];
  protected _items: Record<K, T> = {} as Record<K, T>;

  /**
   * Check whether a key exists
   * @param {K} key
   * @returns {boolean}
   */
  public has(key: K): boolean {
    return typeof this._items[key] != 'undefined';
  }

  /**
   * Get the value attached to a specific key
   * @param {K} key
   * @returns {T}
   */
  public get(key: K): T {
    return this._items[key];
  }

  /**
   * Returns the length of the collection
   * @returns {number} The length of the array.
   */
  public get length(): number {
    return this._order.length;
  }

  /**
   * Get the raw data, to be able to convert in JSON for instance
   * @returns {{order: K[], data: Record<string, T>}}
   */
  public get serialize(): { order: K[]; data: Record<string, T> } {
    return {
      order: this._order,
      data: this._items,
    };
  }

  /**
   * Sets default values for each key and returns the instance.
   * @param {K[]} keys - An array of keys of type K that will be used to set the order of the items
   * @param {T} defaultVal - the default value that will be assigned to each key.
   * @returns {this}
   */
  public from(keys: K[], defaultVal: T): this {
    this._order = keys;
    keys.forEach((k) => {
      this._items[k] = defaultVal;
    });
    return this;
  }

  /**
   * Add a key / value pair - at the end
   * @param {K} key
   * @param {T} value
   * @param {boolean} reorder (optional) - whether we re-order the dictionary if the item already exists, putting it last
   */
  public push(key: K, value: T, reorder?: boolean): number {
    this._updateExisting(key, value, reorder);
    return this._order.push(key);
  }

  /**
   * Add a key / value pair - at the start, will shift the whole array
   * @param {K} key
   * @param {T} value
   * @param {boolean} reorder (optional) - whether we re-order the dictionary if the item already exists, putting it first
   */
  public unshift(key: K, value: T, reorder?: boolean): number {
    this._updateExisting(key, value, reorder);
    return this._order.unshift(key);
  }

  /**
   * Adds a reference to the value in the dictionary, without keeping track of the order
   * Might be useful to add some metadata to the object that won't be looped on.
   * Also serves to directly update a value already existing in the IndexedMap;
   * @param {K} key - The key to reference the value by.
   * @param {T} value - The value to be stored in the map.
   */
  public ref(key: K, value: T): void {
    this._items[key] = value;
  }

  /**
   * If the key exists, remove it from the order array and delete it from the items object
   * @param {K} key - K - The key of the item to remove.
   * @returns {T} - the deleted item
   */
  public remove(key: K): T | null {
    if (!this.has(key)) {
      return null;
    }

    const index = this.indexOf(key);

    // we are treating with a non-ordered item
    if (-1 === index) {
      return this.unref(key);
    }

    return this._extract(() => this._order.splice(index, 1)[0]);
  }

  /**
   * Remove and return the last item of the dictionary
   * @returns {T}
   */
  public pop(): T | null {
    return this._extract(() => this._order.pop() as K);
  }

  /**
   * Remove and return the first item of the dictionary
   * @returns {T}
   */
  public shift(): T | null {
    return this._extract(() => this._order.shift() as K);
  }

  /**
   * It removes an item from the cache without modifying the order, and returns it
   * To be used only with un-ordered items, or you will create bugs with your data
   * @param {K} key - K - The key of the item to remove from the cache.
   * @returns {T} The item that was removed.
   */
  public unref(key: K): T | null {
    const item = this.get(key);
    delete this._items[key];
    return item;
  }

  /**
   * It empties the IndexedMap
   */
  public empty(): void {
    this._items = {} as Record<K, T>;
    this._order = [];
  }

  /**
   * Traverse the IndexedMap's ordered values, and feed them to the callback.
   * If breakable is true, the loop will break if the callback returns a truthy value.
   * If start/end are provided, the loop will only run within this range.
   * If map is provided, it will behave like Array.prototype.map() and return an array.
   * @param callback
   * @param options
   */
  public forEach<R = T>(
    callback: (value: T, key: K, index: number) => R,
    options?: Partial<{ breakable: boolean; start: number; end: number; map: boolean }>,
  ): R[] {
    const map: R[] = [];
    if (!this.length) {
      return map;
    }

    let i = options?.start || 0;
    let end = options?.end || this.length;
    if (0 > i) {
      i = 0;
    }
    if (end > this.length) {
      end = this.length;
    }

    if (i > end) {
      return map;
    }

    if (options?.breakable) {
      for (; i != end; ++i) {
        if (callback(this.get(this._order[i]), this._order[i], i)) {
          break;
        }
      }
    } else if (options?.map) {
      for (; i != end; ++i) {
        map.push(callback(this.get(this._order[i]), this._order[i], i));
      }
    } else {
      for (; i != end; ++i) {
        callback(this.get(this._order[i]), this._order[i], i);
      }
    }

    return map;
  }

  /**
   * Calls a defined callback function on each element of an IndexedMap, and returns an array
   * that contains the results.
   * @param callbackfn A function that accepts up to two arguments. The map method calls the
   * callbackfn function one time for each element in the IndexedMap.
   */
  public map<R = T>(
    callback: (value: T, key: K, index: number) => R,
    options?: Partial<{ start: number; end: number }>,
  ): R[] {
    return this.forEach(callback, { ...options, map: true });
  }

  /**
   * Determines whether all the members of an IndexedMap satisfy the specified test.
   * @param predicate A function that accepts up to two arguments. The every method calls
   * the predicate function for each element in the IndexedMap until the predicate returns a value
   * which is coercible to the Boolean value false, or until the end of the IndexedMap.
   */
  public every(predicate: (item: T, i: number) => boolean): boolean {
    return this._order.every((key, i) => predicate(this._items[key], i));
  }

  /**
   * Determines whether the specified callback function returns true for any element of an IndexedMap.
   * @param predicate A function that accepts up to two arguments. The some method calls
   * the predicate function for each element in the IndexedMap until the predicate returns a value
   * which is coercible to the Boolean value true, or until the end of the IndexedMap.
   */
  public some(predicate: (item: T, i: number) => boolean): boolean {
    return this._order.some((key, i) => predicate(this._items[key], i));
  }

  /**
   * Returns the value of the first element in the IndexedMap where predicate is true, and undefined
   * otherwise.
   * @param predicate find calls predicate once for each element of the IndexedMap, in ascending
   * order, until it finds one where predicate returns true. If such an element is found, find
   * immediately returns that element value. Otherwise, find returns undefined.
   */
  public find(predicate: (item: T, i: number) => boolean): T | undefined {
    const foundKey = this.findKey(predicate);
    if (foundKey !== undefined) {
      return this._items[foundKey];
    }
  }

  /**
   * Returns the key of the first element in the IndexedMap where predicate is true, and undefined
   * otherwise.
   * @param predicate find calls predicate once for each element of the IndexedMap, in ascending
   * order, until it finds one where predicate returns true. If such an element is found, find
   * immediately returns that element key. Otherwise, find returns undefined.
   */
  public findKey(predicate: (item: T, i: number) => boolean): K | undefined {
    return this._order.find((key, i) => predicate(this._items[key], i));
  }

  /**
   * Returns the index of the first occurrence of a value in an IndexedMap, or -1 if it is not present.
   * @param searchElement The value to locate in the IndexedMap.
   * @param fromIndex The IndexedMap index at which to begin the search. If fromIndex is omitted, the search starts at index 0.
   */
  public indexOf(key: K, fromIndex?: number): number {
    return this._order.indexOf(key, fromIndex);
  }

  protected _updateExisting(key: K, value: T, reorder?: boolean): void {
    const existed = this.has(key);

    if (reorder && existed) {
      // delete the current position of the item
      this._order.splice(this.indexOf(key), 1);
    }

    this._items[key] = value;
  }

  protected _extract(getId: () => K): T | null {
    if (!this.length) {
      return null;
    }

    return this.unref(getId());
  }
}
