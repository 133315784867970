/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file scrollControl.ts
 * @desc Created on Thu Mar 16 2023 18:54:38
 * @copyright All rights reserved @ Inarix
 */

export function scrollControl(parent: HTMLElement, scrollY: number): void {
  parent.scrollTo({ left: 0, top: scrollY, behavior: 'auto' });
}
