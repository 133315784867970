/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file imagesSlice.ts
 * @desc Created on Tue May 24 2022 18:37:10
 * @copyright All rights reserved @ Inarix
 */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IMAGES_PER_PAGE, IMAGES_PER_ROW } from '../../Conf';
import { CompositeId, DerivedImage, Image } from '../../declarations/Image';
import { LabelInstance, secondaryId } from '../../declarations/labelInstance';
import { QueriableItem } from '../../declarations/QueriableItem';
import { RootState } from '../store';
import {
  confirmLabelChanges,
  handleKeyboardMove,
  invertLocalSelection,
  setBigImage,
  toggleSelection,
} from '../utils/imageListManips';
import { imagePromiseFactory } from '../utils/imagePromise';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';

export const initialState: {
  initializing: boolean;
  bigImage: string;
  modalImage: string;
  currentPage: number;
  previousPage: number;
  pageStartIdx: number;
  currentLocalIdx: number;
  progress: number;
  data: Record<CompositeId, Image>;
  order: CompositeId[];
  localOrder: CompositeId[];
  selectedLocalIndexes: number[];
  labelTargetIdToCompositeId: Record<string, CompositeId>;
} & QueriableItem = {
  status: 'unfetched',
  initializing: false,
  bigImage: '',
  modalImage: '',
  currentPage: 0,
  previousPage: 0,
  pageStartIdx: 0,
  currentLocalIdx: 0,
  progress: 0,
  data: {},
  labelTargetIdToCompositeId: {},
  order: [],
  localOrder: [],
  selectedLocalIndexes: [],
};

// selectors
const _selectImages = (state: RootState): typeof state.images.data => state.images.data;
const _selectOrder = (state: RootState): typeof state.images.order => state.images.order;
const _selectLocalOrder = (state: RootState): typeof state.images.localOrder =>
  state.images.localOrder;
const _selectHasHighlight = (state: RootState): boolean =>
  !!state.images.selectedLocalIndexes.length;

export const selectLocalIdx = (state: RootState): number => state.images.currentLocalIdx;
export const selectCurrentId = (state: RootState): string => state.images.bigImage;
export const selectHighlighted = (state: RootState): typeof state.images.selectedLocalIndexes =>
  state.images.selectedLocalIndexes;
export const selectPageIdx = (state: RootState): number => state.images.currentPage;
export const selectProgress = (state: RootState): number => state.images.progress;
export const selectLength = (state: RootState): number => state.images.order.length;
export const selectModalImage = (state: RootState): (Image & { box: number[] }) | undefined => {
  const id = state.images.modalImage;
  return id && state.images.data[id]
    ? {
        ...(state.images.data[id] as Image),
        box: (
          state.images.data[state.images.localOrder[state.images.currentLocalIdx]] as DerivedImage
        ).box,
      }
    : undefined;
};
export const selectPageCount = createSelector(_selectOrder, (order) =>
  Math.ceil(order.length / IMAGES_PER_PAGE),
);
export const selectHasHighlight = createSelector(_selectHasHighlight, (highlighted) => {
  return highlighted;
});
export const selectCurrentImage = createSelector(
  _selectImages,
  selectCurrentId,
  (images, current) => (current ? images[current] : undefined),
);
export const selectImagesByPage = createSelector(
  _selectImages,
  _selectLocalOrder,
  selectHighlighted,
  (images, localOrder, highlighted) => {
    return localOrder.map((id, idx) => ({
      ...images[id],
      selected: highlighted.includes(idx),
    })) as Image[];
  },
);
export const selectImagesErr = (state: RootState): typeof state.images.error => state.images.error;
export const selectImagesStatus = (state: RootState): typeof state.images.status =>
  state.images.status;

// thunks
export const fetchSignedUrls = createAsyncThunk(
  'images/fetch',
  async (_: null | undefined, store) => {
    const { state, authHead } = thunkInit(store);
    const data = state.images.data;
    return await imagePromiseFactory(
      state.images.localOrder.map((id) => data[id]) as Image[],
      authHead,
    );
    // return await imagePromiseFactory(selectImagesByPage(state) as Image[], authHead);
  },
);
export const fetchSignedUrl = createAsyncThunk('images/singleFetch', async (id: string, store) => {
  const { state, authHead } = thunkInit(store);
  if (!state.images.data[id]) {
    return null;
  }
  return await imagePromiseFactory([state.images.data[id] as Image], authHead);
});

// slice
const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    clearImages: (): typeof initialState => {
      return initialState;
    },
    setModalImage: (state, action: PayloadAction<string>): void => {
      if (action.payload && !state.data[action.payload]) {
        return;
      }
      state.modalImage = action.payload;
    },
    changeViewMode: (state, action: PayloadAction<boolean>): void => {
      setBigImage(state, action.payload ? -1 : undefined);
    },
    setImages: (
      state,
      action: PayloadAction<{ data: Record<string, Image>; order: string[] }>,
    ): void => {
      state.data = action.payload.data;
      state.order = action.payload.order;
      state.localOrder = action.payload.order.slice(0, IMAGES_PER_PAGE);
      state.initializing = true;

      // the job is made of object views' images
      // TODO how do we know what id to put as index (sample/obj)?
      if ((state.data[state.order[0]] as DerivedImage)?.objectViewId) {
        state.labelTargetIdToCompositeId = state.order.reduce((dict, id) => {
          dict[secondaryId(state.data[id] as DerivedImage)] = id;
          return dict;
        }, {} as Record<string, string>);
      }
    },
    initComplete: (state): void => {
      state.initializing = false;
    },
    setImagePage: (state, action: PayloadAction<number>): void => {
      state.previousPage = state.currentPage;
      state.currentPage = action.payload;
      state.pageStartIdx = action.payload * IMAGES_PER_PAGE;
      state.localOrder = state.order.slice(
        state.pageStartIdx,
        state.pageStartIdx + IMAGES_PER_PAGE,
      );
      state.selectedLocalIndexes = [];
      state.currentLocalIdx = 0;
      setBigImage(state, state.bigImage ? 0 : undefined);
    },
    setImageLabels: (state, action: PayloadAction<LabelInstance[]>): void => {
      confirmLabelChanges(state, action.payload);
    },
    toggleAllPageOn: (state): void => {
      state.selectedLocalIndexes = state.localOrder.map((_, idx) => idx);
      state.currentLocalIdx = state.selectedLocalIndexes.length - 1;
    },
    invertSelected: (state): void => {
      invertLocalSelection(state);
    },
    moveRight: (state, action: PayloadAction<boolean>): void => {
      if (state.localOrder.length - 1 === state.currentLocalIdx) {
        return;
      }
      handleKeyboardMove(state, action.payload, 1);
    },
    moveLeft: (state, action: PayloadAction<boolean>): void => {
      if (0 === state.currentLocalIdx) {
        return;
      }
      handleKeyboardMove(state, action.payload, -1);
    },
    moveDown: (state, action: PayloadAction<boolean>): void => {
      const endLocalIdx = state.localOrder.length - 1;
      if (endLocalIdx === state.currentLocalIdx) {
        return;
      }
      handleKeyboardMove(
        state,
        action.payload,
        Math.min(IMAGES_PER_ROW, endLocalIdx - state.currentLocalIdx),
      );
    },
    moveUp: (state, action: PayloadAction<boolean>): void => {
      if (0 === state.currentLocalIdx) {
        return;
      }
      handleKeyboardMove(state, action.payload, -Math.min(state.currentLocalIdx, IMAGES_PER_ROW));
    },
    toggleSelected: (
      state,
      action: PayloadAction<{ idx: number; shift?: boolean; ctrl?: boolean } | undefined>,
    ): void => {
      if (action.payload) {
        // big image mode: cannot select multiple, only swap the current image
        if (state.bigImage) {
          setBigImage(state, action.payload.idx);
        } else {
          if (!action.payload.shift && !action.payload.ctrl) {
            state.selectedLocalIndexes = [];
          }
          toggleSelection(state, action.payload.idx, action.payload.shift);
        }
      } else {
        state.selectedLocalIndexes = [];
        state.currentLocalIdx = 0;
      }
    },
    toggleLabelInstance: (state, action: PayloadAction<string>): void => {
      const ids = state.selectedLocalIndexes.map((idx) => state.localOrder[idx]);
      ids.forEach((id) => {
        const labels = (state.data[id] as Image).labels;

        let hadLabel = false;
        for (const key in labels) {
          if (labels[key]) {
            hadLabel = true;
            break;
          }
        }

        labels[action.payload] = !labels[action.payload];

        let haveLabel = false;
        for (const key in labels) {
          if (labels[key]) {
            haveLabel = true;
            break;
          }
        }

        if (!hadLabel && haveLabel) {
          ++state.progress;
        } else if (hadLabel && !haveLabel) {
          --state.progress;
        }
      });
    },
  },
  extraReducers(builder) {
    addBaseThunkCases(
      builder,
      [fetchSignedUrls, fetchSignedUrl],
      (
        state: WritableDraft<typeof initialState>,
        action: PayloadAction<Record<string, string>>,
      ) => {
        state.status = 'fulfilled';

        for (const key in action.payload) {
          // keys have been checked before queriing, they exist
          (state.data[key] as Image).url = action.payload[key] as string;
        }
      },
    );
  },
});

// actions
export const {
  clearImages,
  setModalImage,
  setImages,
  initComplete,
  setImagePage,
  setImageLabels,
  toggleAllPageOn,
  invertSelected,
  toggleSelected,
  moveDown,
  moveUp,
  moveLeft,
  moveRight,
  toggleLabelInstance,
  changeViewMode,
} = imagesSlice.actions;
export default imagesSlice.reducer;
