/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Modal.tsx
 * @desc Created on Wed Sep 07 2022 21:20:13
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { ButtonParams } from '../../declarations/DelayedInputParams';
import { Box, Modal } from '@mui/material';
import { useAppDispatch } from '../../redux/hooks';

const BaseModal: FC<ButtonParams & { open: boolean; onClose?: () => void }> = (props) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      open={props.open}
      onClick={(e): void => {
        (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
      }}
      onClose={(): void => {
        if (props.onClose) {
          props.onClose();
        }

        if (Array.isArray(props.action)) {
          props.action.forEach((action) => dispatch(action(props.value)));
        } else {
          dispatch(props.action(props.value));
        }
      }}
    >
      <Box className="mod_content">{props.children}</Box>
    </Modal>
  );
};

export default BaseModal;
