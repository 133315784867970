/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file LabelValues.tsx
 * @desc Created on Tue Jun 07 2022 11:11:22
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { JobStatus } from '../../declarations/Constant';
import { useAppSelector } from '../../redux/hooks';
import { toggleLabelInstance, selectHasHighlight } from '../../redux/slices/imagesSlice';
import { selectCurrentJob } from '../../redux/slices/jobsSlice';
import {
  selectHasFreetext,
  selectLabelValues,
  selectTemplatesError,
  selectTemplatesStatus,
} from '../../redux/slices/labelTemplatesSlice';
import { LabelButton } from '../atomic/io/LabelButton';
import { setModal } from '../../redux/slices/uiSlice';

const LabelValues: FC = () => {
  const values = useAppSelector(selectLabelValues),
    hasNoSelection = !useAppSelector(selectHasHighlight),
    isNotOngoing = JobStatus.current !== useAppSelector(selectCurrentJob)?.statusId,
    disabled = isNotOngoing || hasNoSelection,
    useFreeText = useAppSelector(selectHasFreetext),
    status = useAppSelector(selectTemplatesStatus),
    error = useAppSelector(selectTemplatesError);
  let jsx: JSX.Element[] = [];

  if ('fulfilled' === status) {
    jsx = values.map((val, idx) => (
      <LabelButton
        key={'label_toggle_' + (val.shortcut || '') + idx}
        disabled={val.shortcut ? disabled : true}
        action={toggleLabelInstance}
        {...val}
      />
    ));
    if (useFreeText) {
      // add the extra <+> button for free text modal
      jsx.push(
        <LabelButton
          key="label_toggle_+"
          action={setModal}
          value="addFreeText"
          shortcut="+"
          color={['white', 'red']}
          bypassKeyboard
          bypassValueRender
          mui={{
            className: 'label_btn_freetext',
          }}
        />,
      );
    }
  }
  return (
    <div id="label_btns" className="left">
      {'fulfilled' === status ? jsx : error}
    </div>
  );
};

export default LabelValues;
