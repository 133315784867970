/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file FreetextLabelModal.tsx
 * @desc Created on Wed May 10 2023 19:05:12
 * @copyright All rights reserved @ Inarix
 */

import { FC, FormEventHandler, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentModal, setModal } from '../../redux/slices/uiSlice';
import { addFreeText } from '../../redux/slices/labelTemplatesSlice';
import BaseModal from './Modal';
import ReduxButton from '../atomic/io/ReduxButton';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { themeInarix } from '../MuiTheme';

const FreetextLabelModal: FC = () => {
  const modal = useAppSelector(selectCurrentModal),
    [label, setLabel] = useState(''),
    onInput: FormEventHandler = (e) => {
      setLabel((e.currentTarget as HTMLInputElement).value);
    },
    hideHandler = (): void => {
      setLabel('');
    };

  return (
    <BaseModal open={'addFreeText' === modal} onClose={hideHandler} action={setModal} value={''}>
      <Card
        variant="outlined"
        sx={{ width: 400, margin: 'auto', backgroundColor: 'white', color: 'black' }}
      >
        <ThemeProvider theme={themeInarix}>
          <CardHeader title="New label value" />
          <CardContent>
            <TextField
              variant="outlined"
              size="medium"
              fullWidth
              focused
              type="text"
              label="Label"
              value={label}
              onChange={onInput}
            />
          </CardContent>
          <CardActions>
            <ReduxButton
              action={setModal}
              onSubmit={hideHandler}
              value={''}
              mui={{ variant: 'outlined', fullWidth: true }}
            >
              Cancel
            </ReduxButton>
            <ReduxButton
              disabled={label == ''}
              onSubmit={hideHandler}
              action={[addFreeText, setModal]}
              value={[label, '']}
              mui={{ fullWidth: true }}
            >
              Add
            </ReduxButton>
          </CardActions>
        </ThemeProvider>
      </Card>
    </BaseModal>
  );
};

export default FreetextLabelModal;
