/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Pagination.tsx
 * @desc Created on Tue Jun 07 2022 11:08:36
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectPageIdx, selectPageCount, setImagePage } from '../../redux/slices/imagesSlice';
import ButtonWithKey from '../atomic/io/ButtonWithKey';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ctrlKey } from '../utils/device';

const Pagination: FC = () => {
  const page = useAppSelector(selectPageIdx),
    pageCount = useAppSelector(selectPageCount);

  return (
    <div className="right">
      {page + 1} of {pageCount}
      &nbsp;&nbsp;&nbsp;
      <ButtonWithKey
        disabled={0 === page}
        action={setImagePage}
        value={page - 1}
        shortcut="backspace"
        special={ctrlKey}
        mui={{ variant: 'text', size: 'small' }}
        isIcon
      >
        <NavigateBeforeIcon />
      </ButtonWithKey>
      <ButtonWithKey
        disabled={pageCount === page + 1}
        action={setImagePage}
        value={page + 1}
        shortcut="enter"
        special={ctrlKey}
        mui={{ variant: 'text', size: 'small' }}
        isIcon
      >
        <NavigateNextIcon />
      </ButtonWithKey>
    </div>
  );
};

export default Pagination;
