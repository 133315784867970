/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file labelInstance.ts
 * @desc Created on Tue May 24 2022 18:41:56
 * @copyright All rights reserved @ Inarix
 */
import { CompositeId, DerivedImage, Image } from './Image';
import { LabelTemplateConfig, SimpleLabelTemplate } from './LabelTemplate';
import { User } from './User';
export interface LabelInstance {
  id: string;
  labelTemplateId: string;
  labelTemplateVersionId: string;
  sampleId: string | null;
  imageId: string | null;
  objectId: string | null;
  objectViewId: string | null;
  creatorId: string;
  ownerOrgId: number;
  originPastActionId: string;
  metadata?: object;
  generatedByLabelTemplateConfig?: LabelTemplateConfig;

  rawInput: string; // categorical/classes & freetext
  labelText?: string; // pocketlab input
  labelValue?: number; // continuous labels
  labelJson?: {}; // json labels

  createdAt: string;
  updatedAt: string;
  isTainted: boolean;
  taintedAt?: string;
  taintedBy?: string;
  taintedComments?: string;
  isDeprecated: boolean;
  deprecatedAt?: string;
  deprecatedBy?: string;
  deprecatedComments?: string;
}

export interface LabelInstanceParams {
  samples: string[];
  objectViews: string[];
  templates: string[];
}

export interface DeprecationBody {
  labelInstance: {
    labelInstanceId: string;
    comments: string;
  };
  jobId: string;
  force: boolean;
}

export function secondaryId(item: {
  objectViewId: string | null;
  objectId: string | null;
  sampleId: string | null;
}): string {
  return (item.objectViewId || item.sampleId || item.objectId) as string;
}

export function labelTargetIdToCompositeId(
  label: LabelInstance,
  idMap: Record<string, CompositeId>,
): string {
  if (label.imageId) {
    return label.imageId;
  }
  return idMap[secondaryId(label)];
}

export function labelInstanceFactory(
  tpl: SimpleLabelTemplate,
  image: Image | DerivedImage,
  user: User,
  pastActionId: string,
  value: string | number,
): Partial<LabelInstance> {
  const result: Partial<LabelInstance> = {
    creatorId: user.id,
    ownerOrgId: user.orgId,
    labelTemplateId: tpl.id,
    labelTemplateVersionId: tpl.versionId,
    originPastActionId: pastActionId,
    generatedByLabelTemplateConfig: tpl.config,
    rawInput: `${value}`,
  };

  value = (tpl.config?.substitutionDict && tpl.config.substitutionDict[value]) || value;
  if ('string' === typeof value) {
    result.labelText = value;
  } else {
    result.labelValue = value;
  }

  if ('image' === tpl.scope) {
    result.imageId = image.imageId;
  } else if ('sample' === tpl.scope) {
    result.sampleId = image.sampleId;
  } else if ('object_view' === tpl.scope && (image as DerivedImage).objectViewId) {
    result.objectViewId = (image as DerivedImage).objectViewId;
  } else if ('object' === tpl.scope && (image as DerivedImage).objectViewId) {
    result.objectId = (image as DerivedImage).objectId;
  }

  if (tpl.metadata) {
    result.metadata = tpl.metadata;
  }

  return result;
}
