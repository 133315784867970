/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file LoginForm.tsx
 * @desc Created on Tue May 24 2022 19:06:19
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { LOCAL_STORAGE_KEY_TOKEN } from '../../Conf';
import { useAppSelector } from '../../redux/hooks';
import {
  connectUser,
  passwordInput,
  selectLoginHadInput,
  selectPassHadInput,
  selectUserErr,
  selectUsername,
  selectUserPassword,
  selectUserStatus,
  usernameInput,
} from '../../redux/slices/userSlice';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Snackbar,
  ThemeProvider,
} from '@mui/material';
import { themeInarix } from '../MuiTheme';
import ButtonWithKey from '../atomic/io/ButtonWithKey';
import DelayReduxInput from '../atomic/io/DelayReduxInput';
import Version from '../atomic/Version';

const LoginForm: FC = () => {
  const status = useAppSelector(selectUserStatus),
    errMsg = useAppSelector(selectUserErr);

  if ('unfetched' === status) {
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN); // kinda sucks that we have to do that in the UI...
  }

  return (
    <Box id="login">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={'rejected' === status}
      >
        <Alert severity="error">{errMsg}</Alert>
      </Snackbar>
      <Modal open={true} disableAutoFocus disableEscapeKeyDown>
        <Box className="mod_content">
          <Card sx={{ width: 400, margin: 'auto' }}>
            <CardHeader
              avatar={<Avatar variant="rounded" src={process.env.PUBLIC_URL + 'icon-192.png'} />}
              title="Grains"
              titleTypographyProps={{ fontSize: '1.5em', fontWeight: 'bold' }}
            />
            <ThemeProvider theme={themeInarix}>
              <CardHeader
                title="Please log in"
                sx={{ backgroundColor: 'white', color: 'black' }}
                titleTypographyProps={{ fontSize: '1.3em' }}
              />
              <CardContent sx={{ backgroundColor: 'white' }}>
                <DelayReduxInput
                  type="text"
                  placeholder="Username"
                  pendingSelector={selectUserStatus}
                  valueSelector={selectUsername}
                  isModifiedSelector={selectLoginHadInput}
                  action={usernameInput}
                  mui={{ size: 'normal', fullWidth: true }}
                />
                <br />
                <br />
                <DelayReduxInput
                  type="password"
                  placeholder="Password"
                  pendingSelector={selectUserStatus}
                  valueSelector={selectUserPassword}
                  isModifiedSelector={selectPassHadInput}
                  action={passwordInput}
                  mui={{ size: 'normal', fullWidth: true }}
                />
              </CardContent>
              <CardActions sx={{ backgroundColor: 'white', padding: '16px' }}>
                <ButtonWithKey
                  shortcut="enter"
                  special="none"
                  disabledSelectors={[selectUserStatus, selectUsername, selectUserPassword]}
                  action={connectUser}
                  mui={{ fullWidth: true, size: 'small' }}
                >
                  Log in
                </ButtonWithKey>
              </CardActions>
            </ThemeProvider>
          </Card>
        </Box>
      </Modal>
      <Version />
    </Box>
  );
};

export default LoginForm;
