/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file api.ts
 * @desc Created on Tue Mar 07 2023 12:45:54
 * @copyright All rights reserved @ Inarix
 */

import { AxiosError, AxiosResponse } from 'axios';

export function errorWrap(err: unknown): never {
  if ((err as AxiosError).response?.data?.message) {
    throw new Error((err as AxiosError).response?.data.message);
    // } else if ((err as AxiosError).request) {
    //   throw new Error('No response received');
  } else {
    throw err;
  }
}

export async function queryWrap(resp: Promise<AxiosResponse>): Promise<AxiosResponse> {
  try {
    return await resp;
  } catch (err) {
    errorWrap(err);
  }
}
