/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Conf.ts
 * @desc Created on Tue May 24 2022 18:53:05
 * @copyright All rights reserved @ Inarix
 */
export const LOCAL_STORAGE_KEY_TOKEN = 'usertoken';
export const LOCAL_STORAGE_KEY_JOB = 'currentjob';
export const LOCAL_STORAGE_KEY_PAGE = 'currentpage';
export const IMAGES_PER_PAGE = 20;
export const IMAGES_PER_ROW = 5;
export const INACTIVITY_DELAY = 30 * 1000; // 30s
export const SCENARIO_INSTANCE_ID = 541;
export const PAST_ACTION_SOURCE = 81; // sourceConstantId: 'source_labelling_tool'
export const REQUIRED_SIZE = { width: 1250, height: 1000 };

export const API = process.env.REACT_APP_API_ENDPOINT || 'https://api.inarix.com';
// export const API = process.env.REACT_APP_API_ENDPOINT || 'https://api.staging.inarix.com';
// export const API = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080';
