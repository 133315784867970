/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ReduxButton.tsx
 * @desc Created on Tue May 24 2022 19:06:45
 * @copyright All rights reserved @ Inarix
 */
import { FC, MouseEventHandler } from 'react';
import { ButtonParams } from '../../../declarations/DelayedInputParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Button, IconButton } from '@mui/material';

const ReduxButton: FC<ButtonParams> = (props) => {
  const dispatch = useAppDispatch();
  const disabled =
    props.disabled ||
    props.disabledSelectors
      ?.map((sel) => {
        const val = useAppSelector(sel);
        return !val || 'pending' === val;
      })
      .some((res) => res);
  const submit: MouseEventHandler = (e) => {
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
    if (!props.disabled) {
      if (props.onSubmit) {
        props.onSubmit();
      }
      if (Array.isArray(props.action)) {
        if (Array.isArray(props.value)) {
          props.action.forEach((action, i) => dispatch(action(props.value[i])));
        } else {
          props.action.forEach((action) => dispatch(action(props.value)));
        }
      } else {
        dispatch(props.action(props.value));
      }
    }
  };

  if (props.isIcon) {
    return (
      <IconButton disabled={disabled} onClick={submit} {...props.mui}>
        {props.children}
      </IconButton>
    );
  }
  return (
    <Button variant="contained" disabled={disabled} onClick={submit} {...props.mui}>
      {props.children}
    </Button>
  );
};

export default ReduxButton;
