/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Unloader.tsx
 * @desc Created on Wed Jul 06 2022 06:29:23
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { JobStatus } from '../../../declarations/Constant';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { saveJobPage } from '../../../redux/slices/jobSlice';
import { selectCurrentJob } from '../../../redux/slices/jobsSlice';
import VoidHandlerEffect from './VoidHandlerEffect';

const Unloader: FC = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectCurrentJob);
  const handler = (e: any): void => {
    if (!job || JobStatus.current !== job.statusId) {
      return;
    }

    dispatch(saveJobPage());

    // blocking page close just to give enough time to queries to go out
    if ('beforeunload' === e.type) {
      e.preventDefault();
      return (e.returnValue =
        'You have unfinished changes. Please return to the jobs list to mark this one as completed. Are you sure you want to exit?' as any);
    }
  };

  return (
    <div>
      <VoidHandlerEffect handler={handler} event="beforeunload" />
      <VoidHandlerEffect handler={handler} event="blur" />
    </div>
  );
};

export default Unloader;
