/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ClearFloat.tsx
 * @desc Created on Mon Jun 13 2022 18:06:27
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';

const ClearFloat: FC = () => {
  return <div className="clear">&nbsp;</div>;
};

export default ClearFloat;
