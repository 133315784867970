/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ZoomDetector.tsx
 * @desc Created on Wed Mar 08 2023 15:15:47
 * @copyright All rights reserved @ Inarix
 */

import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { openSnack, selectHasCheckedZoom, setZoomCheck } from '../../redux/slices/uiSlice';
import { REQUIRED_SIZE } from '../../Conf';
import VoidHandlerEffect from '../atomic/io/VoidHandlerEffect';

const ZoomDetector: FC = () => {
  const dispatch = useAppDispatch(),
    zoomWasChecked = useAppSelector(selectHasCheckedZoom),
    resizeHandler = (): void => {
      dispatch(setZoomCheck(false));
    };

  useEffect(() => {
    if (zoomWasChecked) {
      return;
    }

    // let verticalCorrectAttemptSuccess = false;
    // const zoom = Math.round(((window.outerWidth - 10) / window.innerWidth) * 100);
    const smallestSpaceRatio = Math.min(
      Math.round((window.innerWidth / REQUIRED_SIZE.width) * 100),
      Math.round((window.innerHeight / REQUIRED_SIZE.height) * 100),
      100,
    );
    try {
      // my declarations are missing the "zoom" property :(
      (document.body.style as any).zoom = `${smallestSpaceRatio}%`;
      // verticalCorrectAttemptSuccess = true;
    } catch (_e) {
      dispatch(
        openSnack({
          type: 'info',
          message:
            'Your screen height is a bit low, please consider going full screen, or zooming out',
        }),
      );
    }
    // if (!verticalCorrectAttemptSuccess && (zoom <= 95 || 105 <= zoom)) {
    //   dispatch(
    //     openSnack({
    //       type: 'info',
    //       message: `Your zoom level is at ~${zoom}%, this might affect your experience with the tool. Please consider correcting it!`,
    //     }),
    //   );
    // }

    dispatch(setZoomCheck(true));
  });

  return <VoidHandlerEffect event="resize" handler={resizeHandler} />;
};

export default ZoomDetector;
