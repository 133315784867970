/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file JobPage.tsx
 * @desc Created on Wed Jun 01 2022 20:48:35
 * @copyright All rights reserved @ Inarix
 */

import { FC } from 'react';
import { Job } from '../../declarations/Job';
import { useAppSelector } from '../../redux/hooks';
import { selectJobErr, selectJobStatus } from '../../redux/slices/jobSlice';
import { selectCurrentJob } from '../../redux/slices/jobsSlice';
import { Box, Divider, Typography } from '@mui/material';
import Deselecter from '../atomic/io/Deselecter';
import Spinner from '../atomic/Spinner';
import Unloader from '../atomic/io/Unloader';
import BigImage from '../sections/BigImage';
import ClearFloat from '../atomic/ClearFloat';
import ImageList from '../sections/ImageList';
import JobDetails from '../sections/JobDetails';
import LabelValues from '../sections/LabelValues';
import Pagination from '../sections/Pagination';
import ImageModal from '../sections/ImageModal';
import ImageModeSwap from '../sections/ImageModeSwap';
import CloseJobModal from '../sections/CloseJobModal';
import FreetextLabelModal from '../sections/FreetextLabelModal';
import { getFolderIcon } from '../utils/folderIcon';

const JobPage: FC = () => {
  const status = useAppSelector(selectJobStatus),
    err = useAppSelector(selectJobErr),
    job = useAppSelector(selectCurrentJob) as Job;

  if (job && 'fulfilled' === status) {
    return (
      <Box sx={{ width: 1160, margin: '7vh auto 0' }}>
        <Deselecter />
        <Unloader />
        <ImageModal />
        <CloseJobModal />
        <FreetextLabelModal />
        <div id="header">
          <div className="left">
            <Typography sx={{ fontSize: 24 }}>
              {getFolderIcon(job.statusId, {
                sx: { mr: 2, lineHeight: 24, display: 'inline-block', verticalAlign: 'middle' },
              })}
              {job.name}
            </Typography>
          </div>
          <div className="right">
            <ImageModeSwap />
          </div>
          <ClearFloat />
        </div>
        <Divider />
        <div id="body" className="flex">
          <ImageList />
          <BigImage />
          <JobDetails />
        </div>
        <Divider />
        <div id="bottom">
          <LabelValues />
          <Pagination />
          <ClearFloat />
        </div>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: 1160, margin: '7vh auto 0' }}>
        {'pending' === status && <Spinner />}
        {'rejected' === status && <h2>{err}</h2>}
        {'unfetched' === status && <h2>Please select a job</h2>}
      </Box>
    );
  }
};

export default JobPage;
