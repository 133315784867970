/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file LabelDot.tsx
 * @desc Created on Wed Jun 08 2022 22:51:20
 * @copyright All rights reserved @ Inarix
 */
import { FC } from 'react';

const LabelDot: FC<{ color: string }> = (props) => {
  return <div className="label_dot" style={{ backgroundColor: props.color }}></div>;
};

export default LabelDot;
