/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ImageModal.tsx
 * @desc Created on Wed Sep 07 2022 21:25:06
 * @copyright All rights reserved @ Inarix
 */

import { FC, SyntheticEvent, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectModalImage, setModalImage } from '../../redux/slices/imagesSlice';
import { Slider } from '@mui/material';
import BaseModal from './Modal';
import Spinner from '../atomic/Spinner';

const ImageModal: FC = () => {
  const [zoom, setZoom] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const data = useAppSelector(selectModalImage);
  const onLoaded = (e: SyntheticEvent): void => {
    console.log('loadedhandler');
    const img = e.target as HTMLImageElement;
    const updatedZoom = (e as any).zoom || zoom;
    const canvas = document.getElementById('mod_canvas') as HTMLCanvasElement;
    canvas.width = img.clientWidth;
    canvas.height = img.clientHeight;

    const box = data?.box as number[];
    const imgCoords = [0, 0, img.naturalWidth, img.naturalHeight];
    let boxCoords: number[] | null = null;

    if (box.length == 4) {
      const ratio = canvas.width / img.naturalWidth;

      // this is supposing [x0, y0, x1, y1] coordinates
      // multiply by ratio to get from real pixels to canvas-size pixels
      const boxX = box[0] * ratio;
      const boxY = box[1] * ratio;
      const boxW = (box[2] - box[0]) * ratio;
      const boxH = (box[3] - box[1]) * ratio;

      // displacement of box top-left corner
      const zoomX = (boxX * updatedZoom) / 100;
      const zoomY = (boxY * updatedZoom) / 100;
      // displacement of box bottom right corner (ie to add to width & height)
      const zoomX2 = ((canvas.width - (boxX + boxW)) * updatedZoom) / 100;
      const zoomY2 = ((canvas.height - (boxY + boxH)) * updatedZoom) / 100;

      // -5 & +10 to add some margin around the grain
      boxCoords =
        updatedZoom > 85
          ? null
          : [
              boxX - zoomX - 5,
              boxY - zoomY - 5,
              boxW + zoomX + zoomX2 + 10,
              boxH + zoomY + zoomY2 + 10,
            ];

      // divide by ratio to get back to "real" pixels
      imgCoords[0] += zoomX / ratio;
      imgCoords[1] += zoomY / ratio;
      imgCoords[2] -= (zoomX + zoomX2) / ratio;
      imgCoords[3] -= (zoomY + zoomY2) / ratio;
    }

    // anys here because the linter is dumb and cannot recognize tuples
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    (ctx as any).drawImage(img, ...imgCoords, 0, 0, canvas.width, canvas.height);

    if (boxCoords) {
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      (ctx as any).strokeRect(...boxCoords);
    }

    !loaded && setLoaded(true);
  };
  const handleZoom = (e: Event, newValue: number | number[]): void => {
    e.preventDefault();
    setZoom(newValue as number);
    onLoaded({ target: imgRef.current, zoom: newValue } as any);
  };

  return (
    <BaseModal
      open={!!data}
      action={setModalImage}
      value={''}
      onClose={(): void => {
        setZoom(0);
        setLoaded(false);
      }}
    >
      {!loaded && <Spinner />}
      <canvas id="mod_canvas" />
      {data?.box && loaded ? (
        <Slider
          aria-label="Zoom level"
          value={zoom}
          onChange={handleZoom}
          sx={{ width: '33%', marginTop: 4, marginLeft: '33%' }}
        />
      ) : null}
      <img ref={imgRef} onLoad={onLoaded} src={data?.url} alt="Original image" />
    </BaseModal>
  );
};

export default ImageModal;
