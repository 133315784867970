/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ImageList.tsx
 * @desc Created on Tue May 24 2022 19:05:28
 * @copyright All rights reserved @ Inarix
 */
import { FC, useEffect, useRef } from 'react';
import { useAppSelector } from '../../redux/hooks';
import {
  selectImagesErr,
  selectImagesByPage,
  selectImagesStatus,
  selectHighlighted,
  selectCurrentId,
  selectLocalIdx,
  moveRight,
  moveDown,
  moveUp,
  moveLeft,
} from '../../redux/slices/imagesSlice';
import { selectColorMap, selectColors } from '../../redux/slices/labelTemplatesSlice';
import ImageThumb from '../atomic/ImageThumb';
import Spinner from '../atomic/Spinner';
import VoidWithKey from '../atomic/io/VoidWithKey';
import VoidHandlerEffect from '../atomic/io/VoidHandlerEffect';
import LabelDot from '../atomic/LabelDot';
import { scrollControl } from '../utils/scrollControl';
import { ctrlKey } from '../utils/device';

const ImageList: FC = () => {
  const imageStatus = useAppSelector(selectImagesStatus),
    imageErr = useAppSelector(selectImagesErr),
    images = useAppSelector(selectImagesByPage),
    colorMap = useAppSelector(selectColorMap),
    colors = useAppSelector(selectColors),
    bigId = useAppSelector(selectCurrentId),
    selected = useAppSelector(selectHighlighted),
    keyboardIdx = useAppSelector(selectLocalIdx),
    prevBigId = useRef(''),
    prevStatus = useRef(''),
    prevActionIsKeyboard = useRef(false),
    valueFn = (e: KeyboardEvent): boolean => {
      prevActionIsKeyboard.current = true;
      return e.shiftKey;
    },
    clickDetect = (): void => {
      prevActionIsKeyboard.current = false;
    };

  useEffect(() => {
    const div = document.getElementById('img_list');
    if (div) {
      // user just changed page and is in "big image" mode, gotta scroll back to top
      if (bigId && imageStatus == 'fulfilled' && imageStatus != prevStatus.current) {
        scrollControl(div, 0);
      }
      // user just swapped between "big image" and "image list" modes
      else if (bigId && !prevBigId.current) {
        div.classList.add('small');
        scrollControl(div, selected[0] * 166);
      } else if (!bigId && prevBigId.current) {
        div.classList.remove('small');
      } else if (prevActionIsKeyboard.current) {
        scrollControl(div, selected[0] * 166);
      }
    }

    prevBigId.current = bigId;
    prevStatus.current = imageStatus;
  }, [bigId, imageStatus]);

  let jsxImages: JSX.Element[] = [];
  if (images.length) {
    jsxImages = images.map((img, idx) => (
      <ImageThumb key={img.id} img={img} idx={idx}>
        {Object.keys(img.labels).map((label) =>
          img.labels[label] && undefined !== colorMap[label] ? (
            <LabelDot key={`${img.id}-${label}`} color={colors[colorMap[label] as number][0]} />
          ) : null,
        )}
      </ImageThumb>
    ));
  }

  return (
    <div id="img_list_container">
      {'rejected' === imageStatus && <h2>{imageErr}</h2>}
      {!images[images.length - 1]?.url && <Spinner />}
      <div id="img_list" className="flex">
        {jsxImages}
        <VoidHandlerEffect handler={clickDetect} event="mousedown" />
        <VoidWithKey
          disabled={0 === keyboardIdx}
          shortcut="arrowup"
          special={ctrlKey}
          action={moveUp}
          valueFn={valueFn}
        />
        <VoidWithKey
          disabled={0 === keyboardIdx}
          shortcut="arrowleft"
          special={ctrlKey}
          action={moveLeft}
          valueFn={valueFn}
        />
        <VoidWithKey
          disabled={jsxImages.length - 1 === keyboardIdx}
          shortcut="arrowright"
          special={ctrlKey}
          action={moveRight}
          valueFn={valueFn}
        />
        <VoidWithKey
          disabled={jsxImages.length - 1 === keyboardIdx}
          shortcut="arrowdown"
          special={ctrlKey}
          action={moveDown}
          valueFn={valueFn}
        />
      </div>
    </div>
  );
};

export default ImageList;
