/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file jobsSlice.ts
 * @desc Created on Tue May 24 2022 18:24:55
 * @copyright All rights reserved @ Inarix
 */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { JobStatus } from '../../declarations/Constant';
import { Job } from '../../declarations/Job';
import { APIFindResult, QueriableItem } from '../../declarations/QueriableItem';
import { RootState } from '../store';
import { formatDate } from '../utils/date';
import { jobsListUrl, jobUrl } from '../utils/queries';
import { queryWrap } from '../utils/queryWrapper';
import { addBaseThunkCases, thunkInit } from '../utils/thunks';

export const initialState: {
  current: string;
  assignedOrder: string[];
  superviseOrder: string[];
  data: Record<string, Job>;
} & QueriableItem = {
  status: 'unfetched',
  current: '',
  data: {},
  assignedOrder: [],
  superviseOrder: [],
};

function jobsListFactory(jobs: Record<string, Job>, order: string[]): Job[][] {
  const list = order.map((id) => jobs[id] as Job);
  return Object.keys(JobStatus)
    .filter((val) => !isNaN(parseInt(val)))
    .map((val) => parseInt(val))
    .map((val) => list.filter((job) => job.statusId === val));
}

// selectors
const _selectJobs = (state: RootState): typeof state.jobs.data => state.jobs.data;
export const selectOrder = (state: RootState): typeof state.jobs.assignedOrder =>
  state.jobs.assignedOrder;
export const selectAdminOrder = (state: RootState): typeof state.jobs.superviseOrder =>
  state.jobs.superviseOrder;

export const selectCurrentJobId = (state: RootState): string => state.jobs.current;
export const selectJobs = createSelector(_selectJobs, selectOrder, jobsListFactory);
export const selectSupervisedJobs = createSelector(_selectJobs, selectAdminOrder, jobsListFactory);
export const selectCurrentJob = createSelector(
  _selectJobs,
  selectCurrentJobId,
  (jobs, current) => jobs[current],
);
export const selectJobsErr = (state: RootState): typeof state.jobs.error => state.jobs.error;
export const selectJobsStatus = (state: RootState): typeof state.jobs.status => state.jobs.status;

// thunks
export const fetchJobs = createAsyncThunk('jobs/list', async (doFilter: boolean, store) => {
  const { state, authHead } = thunkInit(store);
  if (state.jobs.current) {
    return;
  }
  const { id, orgId } = state.user.data;
  if (doFilter) {
    return {
      jobs: (
        (await queryWrap(axios.get(jobsListUrl, authHead))).data as APIFindResult<Job>
      ).data.filter(
        (job) => !job.assignedUser || job.assignedUser === id || job.jobSupervisor === id,
      ),
      userId: id,
      orgId,
    };
  }
  // const res = (await axios.get(jobsListUrl, authHead)).data as APIFindResult<Job>;
  // await Promise.all(
  //   res.data
  //     .filter((job) => undefined == JobStatus[job.statusId])
  //     .map((job) => axios.patch(jobUrl(job.id), { statusId: 19 }, authHead)),
  // );
  // return res.data;
  return {
    jobs: ((await queryWrap(axios.get(jobsListUrl, authHead))).data as APIFindResult<Job>).data,
    userId: id,
    orgId,
  };
});
export const patchCurrentJob = createAsyncThunk(
  'jobs/patchCurrent',
  async (changes: Partial<Job>, store) => {
    if (!changes || !Object.keys(changes).length) {
      return {};
    }
    const { state, authHead } = thunkInit(store);
    const jobId = changes.id || state.jobs.current;
    if (!jobId) {
      throw new Error('No selected job');
    }
    delete changes.id;
    await queryWrap(axios.patch(jobUrl(jobId), changes, authHead));
    return changes;
  },
);

// slice
const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    clearJobs: (): typeof initialState => {
      return initialState;
    },
    jobSelected: (state, action: PayloadAction<string>): void => {
      if (!action.payload || state.data[action.payload]) {
        state.current = action.payload;
      }
    },
  },
  extraReducers(builder) {
    // patchCurrentJob() isn't configured here intentionally, we don't want it to change the .status
    addBaseThunkCases(builder, [fetchJobs]);

    builder.addCase(fetchJobs.fulfilled, (state, action): void => {
      state.status = 'fulfilled';
      if (!action.payload) {
        state.data = initialState.data;
        state.assignedOrder = initialState.assignedOrder;
        state.superviseOrder = initialState.superviseOrder;
        return;
      }

      state.data = action.payload.jobs.reduce((dict, job) => {
        job.createdAtMs = new Date(job.createdAt).getTime();
        job.createdAt = formatDate(job.createdAt);
        job.expireAt = formatDate(job.expireAt as string);
        dict[job.id] = job;
        return dict;
      }, {} as Record<string, Job>);

      const { userId, orgId } = action.payload;
      state.assignedOrder = action.payload.jobs
        .filter((job) => !job.assignedUser || job.assignedUser == userId)
        .map((job) => job.id);
      state.superviseOrder = action.payload.jobs
        .filter(
          (job) =>
            job.creatorId == userId ||
            job.jobSupervisor == userId ||
            job.jobSupervisorsGroup == orgId,
        )
        .map((job) => job.id);
    });
    builder.addCase(patchCurrentJob.fulfilled, (state, action): void => {
      // data should be properly defined here, bu we never know
      if (state.current && state.data[state.current]) {
        state.data[state.current] = {
          ...state.data[state.current],
          ...action.payload,
        } as Job;
      }
    });
  },
});

// actions
export const { clearJobs, jobSelected } = jobsSlice.actions;
export default jobsSlice.reducer;
