/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file uiSlice.ts
 * @desc Created on Fri Sep 23 2022 16:10:29
 * @copyright All rights reserved @ Inarix
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AlertColor } from '@mui/material';
import { JobTabNames, ModalNames, SnackBarProps } from '../../declarations/UiProps';
import { LabelInstance } from '../../declarations/labelInstance';

export const initialState: {
  snackBar: SnackBarProps;
  jobTabs: keyof typeof JobTabNames;
  modal: keyof typeof ModalNames | '';
  hasCheckedZoom: boolean;
  labelInstance: LabelInstance | null;
} = {
  snackBar: {
    open: false,
    severity: 'info',
    message: '',
  },
  jobTabs: 'job',
  modal: '',
  hasCheckedZoom: true,
  labelInstance: null,
};

// selectors
export const selectSnack = (state: RootState): typeof initialState.snackBar => state.ui.snackBar;
export const selectJobTab = (state: RootState): typeof initialState.jobTabs => state.ui.jobTabs;
export const selectCurrentModal = (state: RootState): typeof initialState.modal => state.ui.modal;
export const selectHasCheckedZoom = (state: RootState): typeof initialState.hasCheckedZoom =>
  state.ui.hasCheckedZoom;
export const selectLabelInstanceFocus = (state: RootState): typeof initialState.labelInstance =>
  state.ui.labelInstance;

// slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetUI: (): typeof initialState => {
      return initialState;
    },
    hideSnack: (state): void => {
      state.snackBar.open = false;
    },
    openSnack: (state, action: PayloadAction<{ message: string; type: AlertColor }>): void => {
      state.snackBar.open = true;
      state.snackBar.message = action.payload.message;
      state.snackBar.severity = action.payload.type;
    },
    setJobTab: (state, action: PayloadAction<typeof initialState.jobTabs>): void => {
      state.jobTabs = action.payload;
    },
    setModal: (state, action: PayloadAction<typeof initialState.modal>): void => {
      state.modal = action.payload;
    },
    setZoomCheck: (state, action: PayloadAction<boolean>): void => {
      state.hasCheckedZoom = action.payload;
    },
    setLabelInstanceFocus: (
      state,
      action: PayloadAction<typeof initialState.labelInstance | undefined>,
    ): void => {
      if (action.payload) {
        state.labelInstance = action.payload;
      } else {
        state.labelInstance = initialState.labelInstance;
      }
    },
  },
});

// actions
export const {
  hideSnack,
  openSnack,
  resetUI,
  setJobTab,
  setLabelInstanceFocus,
  setModal,
  setZoomCheck,
} = uiSlice.actions;
export default uiSlice.reducer;
