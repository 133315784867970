/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file ActivityDetector.tsx
 * @desc Created on Thu Mar 09 2023 11:52:48
 * @copyright All rights reserved @ Inarix
 */

import { FC, useEffect, useRef } from 'react';
import { INACTIVITY_DELAY } from '../../../Conf';
import { useAppDispatch } from '../../../redux/hooks';
import { addElapsedTime } from '../../../redux/slices/jobSlice';
import { Clock } from '../../utils/clock';

const ActivityDetector: FC = () => {
  const timeoutId = useRef(0);
  const dispatch = useAppDispatch();

  const timeoutHandler = (): void => {
    Clock.instance.stop(); // the user is having a break, let's stop counting
  };
  const eventHandler = (): void => {
    window.clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(timeoutHandler, INACTIVITY_DELAY);

    if (!Clock.instance.running) {
      Clock.instance.start();
      dispatch(addElapsedTime(1));
    }
  };

  useEffect(() => {
    timeoutId.current = window.setTimeout(timeoutHandler, INACTIVITY_DELAY);
    window.addEventListener('click', eventHandler);
    window.addEventListener('keydown', eventHandler);

    return (): void => {
      window.clearTimeout(timeoutId.current);
      window.removeEventListener('click', eventHandler);
      window.removeEventListener('keydown', eventHandler);
    };
  });

  return null;
};

export default ActivityDetector;
