/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file hooks.ts
 * @desc Created on Tue May 24 2022 18:52:39
 * @copyright All rights reserved @ Inarix
 */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
