/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Logout.tsx
 * @desc Created on Mon Aug 22 2022 19:20:31
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { clearJob, saveJobPage } from '../../../redux/slices/jobSlice';
import { selectCurrentJob } from '../../../redux/slices/jobsSlice';
import { logout } from '../../../redux/slices/userSlice';
import { JobStatus } from '../../../declarations/Constant';
import { Button } from '@mui/material';

const Logout: FC = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectCurrentJob);
  const handler: MouseEventHandler = async (e) => {
    (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;

    if (
      !job ||
      JobStatus.current !== job.statusId ||
      confirm('Are you sure you want to log out without finishing the job?')
    ) {
      await dispatch(saveJobPage());
      dispatch(clearJob());
      dispatch(logout());
    }
  };

  return (
    <Button size="small" onClick={handler}>
      Log out
    </Button>
  );
};

export default Logout;
