/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file index.tsx
 * @desc Created on Tue May 24 2022 18:53:15
 * @copyright All rights reserved @ Inarix
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import appStore from './redux/store';
import { refreshUser } from './redux/slices/userSlice';

import App from './components/pages/App';
import { LOCAL_STORAGE_KEY_TOKEN } from './Conf';

// is this allowed here?
const prevToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
if (prevToken) {
  appStore.dispatch(refreshUser(prevToken)); // reconnect from saved token
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
