/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file imageListManips.ts
 * @desc Created on Fri Jun 03 2022 21:09:45
 * @copyright All rights reserved @ Inarix
 */

import { WritableDraft } from 'immer/dist/internal';
import { initialState } from '../slices/imagesSlice';
import { IMAGES_PER_PAGE } from '../../Conf';
import { LabelInstance, labelTargetIdToCompositeId } from '../../declarations/labelInstance';
import { Image } from '../../declarations/Image';

/**
 * It takes a new index, a boolean indicating whether shift is enabled, and an array of current
 * indexes, and returns an array of indexes to add to the current selection
 * @param {number} newIdx - the index of the image that was clicked
 * @param {boolean} shiftEnabled - boolean - whether or not the shift key is currently pressed
 * @param {number[]} currentIndexes - the current selected indexes
 * @returns An array of numbers
 */
export function addSearchBound(
  newIdx: number,
  currentIndexes: number[],
  shiftEnabled?: boolean,
): number[] {
  const toAdd: number[] = [];

  if (shiftEnabled && currentIndexes.length) {
    let diff = IMAGES_PER_PAGE,
      foundIdx = 0;

    // find the closest other index
    currentIndexes.forEach((val, i) => {
      const _diff = Math.abs(val - newIdx);
      if (_diff < diff) {
        diff = _diff;
        foundIdx = i;
      }
    });

    // also add all items between the new selection and the closest selected
    let boundingVal = currentIndexes[foundIdx];
    if (boundingVal > newIdx) {
      while (boundingVal > newIdx) {
        toAdd.push(--boundingVal);
      }
    } else {
      while (boundingVal < newIdx) {
        toAdd.push(++boundingVal);
      }
    }
  } else {
    toAdd.push(newIdx);
  }

  return toAdd;
}

/**
 * It returns the start and end index of a serie of selected images
 * @param {number} idxInList - the index of the image in the list of selected images
 * @param {boolean} shiftEnabled - boolean
 * @param {number[]} list - the list of selected images
 * @returns An array of two numbers.
 */
// TODO change return type to [number, number] when eslint is fixed
export function rmSearchBounds(
  idxInList: number,
  list: number[],
  shiftEnabled?: boolean,
): number[] {
  let deltaForwards = 1,
    deltaBackWards = 1,
    startIdx = idxInList,
    endIdx = idxInList;

  if (shiftEnabled && list.length > 2) {
    // find closest end of a serie of selected images
    for (let i = idxInList; i < list.length; ++i) {
      endIdx = i + 1;
      if (list[i] + 1 !== list[endIdx]) {
        break;
      }
      deltaForwards = endIdx - idxInList;
    }
    for (let i = idxInList; i != 0; --i) {
      startIdx = i - 1;
      if (list[i] - 1 !== list[startIdx]) {
        break;
      }
      deltaBackWards = idxInList - startIdx;
    }

    if (deltaForwards > deltaBackWards) {
      return [startIdx, idxInList + 1];
    } else {
      return [idxInList, endIdx];
    }
  } else {
    return [idxInList, 1];
  }
}

/**
 * If the index is undefined, set the big image to an empty string and clear the selected indexes;
 * otherwise, set the big image to the image at the given index and set the selected indexes to an
 * array containing the given index.
 * @param state - WritableDraft<typeof initialState>
 * @param {number} [idx] - The index of the image to be displayed in the big image. If undefined, the
 * big image will be hidden.
 */
export function setBigImage(state: WritableDraft<typeof initialState>, idx?: number): void {
  // deselect the big image
  if (undefined === idx) {
    state.bigImage = '';
    if (state.selectedLocalIndexes.length) {
      state.selectedLocalIndexes = [state.selectedLocalIndexes[0]];
      state.currentLocalIdx = state.selectedLocalIndexes[0];
    } else {
      state.selectedLocalIndexes = [];
      state.currentLocalIdx = 0;
    }
  } else {
    // we're swapping from grid to big image mode, maybe we have something already selected?
    if (idx < 0) {
      idx = state.selectedLocalIndexes.length ? state.selectedLocalIndexes[0] : 0;
    }
    state.bigImage = state.localOrder[idx];
    state.selectedLocalIndexes = [idx];
    state.currentLocalIdx = idx;
  }
}

/**
 * It adds or removes an image index from the selected indexes array, and if shift is enabled, it adds
 * or removes the indexes in between the current index and the last selected index
 * @param state - the state object
 * @param {number} imgIdx - the index of the image that was clicked
 * @param {boolean} shiftEnabled - boolean
 */
export function toggleSelection(
  state: WritableDraft<typeof initialState>,
  imgIdx: number,
  shiftEnabled?: boolean,
  appendOnly?: boolean,
): void {
  const idx = state.selectedLocalIndexes.indexOf(imgIdx);

  // selecting the image
  if (-1 === idx) {
    state.selectedLocalIndexes.push(
      ...addSearchBound(imgIdx, state.selectedLocalIndexes, shiftEnabled),
    );
    state.selectedLocalIndexes.sort((a, b) => a - b);
    state.currentLocalIdx = imgIdx;
  }
  // deselecting the image
  else if (!appendOnly) {
    const [start, end] = rmSearchBounds(idx, state.selectedLocalIndexes, shiftEnabled);
    state.selectedLocalIndexes.splice(start, end);
    // TODO tuple problem here again
    // state.selectedIndexes.splice(...rmSearchBounds(idx, shiftEnabled, state.selectedIndexes));
  }
}

/**
 * If the index is not in the selection, add it to the new selection.
 * Then overwrite the old selection with the new.
 * @param state - WritableDraft<typeof initialState>
 */
export function invertLocalSelection(state: WritableDraft<typeof initialState>): void {
  const newSelection: number[] = [];
  state.localOrder.forEach((_, idx) => {
    if (-1 === state.selectedLocalIndexes.indexOf(idx)) {
      newSelection.push(idx);
    }
  });
  state.selectedLocalIndexes = newSelection;
  state.currentLocalIdx = newSelection[0] || 0;
}

/**
 * It handles keyboard navigation
 * @param state - WritableDraft<typeof initialState>
 * @param {boolean} shiftEnabled - whether the shift key is pressed
 * @param {number} diff - the number of images to move forward or backward.
 */
export function handleKeyboardMove(
  state: WritableDraft<typeof initialState>,
  shiftEnabled: boolean,
  diff: number,
): void {
  if (state.bigImage) {
    return setBigImage(state, state.currentLocalIdx + diff);
  }
  if (shiftEnabled && !state.selectedLocalIndexes.length) {
    state.selectedLocalIndexes = [state.currentLocalIdx];
  }
  state.currentLocalIdx += diff;
  if (!shiftEnabled) {
    state.selectedLocalIndexes = [state.currentLocalIdx];
  }
  toggleSelection(state, state.currentLocalIdx, shiftEnabled, true);
}

/**
 * It takes a list of labels and adds them to the state
 * @param state - WritableDraft<typeof initialState>
 * @param {LabelInstance[]} changes - LabelInstance[]
 */
export function confirmLabelChanges(
  state: WritableDraft<typeof initialState>,
  changes: LabelInstance[],
): void {
  if (!changes.length) {
    return;
  }

  if (state.initializing) {
    const counts: Record<string, boolean> = {};

    changes.forEach((label) => {
      const imgId = labelTargetIdToCompositeId(
        label,
        state.labelTargetIdToCompositeId as Record<string, string>,
      );
      if (state.data[imgId]) {
        if (!Object.keys((state.data[imgId] as Image).labels).length) {
          counts[imgId] = true;
        }
        (state.data[imgId] as Image).labels[label.rawInput] = true;
      }
    });

    state.progress += Object.keys(counts).length;
  } else {
    changes.forEach((label) => {
      const imgId = labelTargetIdToCompositeId(
        label,
        state.labelTargetIdToCompositeId as Record<string, string>,
      );
      if (state.data[imgId]) {
        (state.data[imgId] as Image).labels[label.rawInput] = true;
      }
    });
  }
}
