/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file JobsList.tsx
 * @desc Created on Sat May 28 2022 00:26:38
 * @copyright All rights reserved @ Inarix
 */

import { FC, MouseEventHandler, useState } from 'react';
import { JobStatus } from '../../declarations/Constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { saveJobPage } from '../../redux/slices/jobSlice';
import {
  selectJobsErr,
  selectJobs,
  selectSupervisedJobs,
  selectJobsStatus,
  jobSelected,
  selectCurrentJobId,
  selectAdminOrder,
} from '../../redux/slices/jobsSlice';
import { selectImagesStatus } from '../../redux/slices/imagesSlice';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Switch,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FolderIcons } from '../utils/folderIcon';
import Spinner from '../atomic/Spinner';

const JobsList: FC<{ width: number }> = ({ width }) => {
  const jobsStatus = useAppSelector(selectJobsStatus),
    imagesStatus = useAppSelector(selectImagesStatus),
    jobsErr = useAppSelector(selectJobsErr),
    jobs = useAppSelector(selectJobs),
    supervisedJobs = useAppSelector(selectSupervisedJobs),
    hasSupervisedJobs = !!useAppSelector(selectAdminOrder).length,
    currJobId = useAppSelector(selectCurrentJobId),
    listIsDisabled = !!currJobId && ('unfetched' == imagesStatus || 'pending' == imagesStatus),
    dispatch = useAppDispatch(),
    handleClick: MouseEventHandler = async (e) => {
      if ('pending' === jobsStatus) {
        return;
      }
      (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
      // save id, because .currentTarget won't be defined after the promise resolves
      // should we use .target ?
      const id = e.currentTarget.id;
      await dispatch(saveJobPage());
      dispatch(jobSelected(id));
    },
    [showAdmin, setShowAdmin] = useState(false),
    openList = [0, 1, 2, 3, 4].map((i) => useState(i < 3)), // JobStatus has 5 values
    _j = showAdmin ? supervisedJobs : jobs,
    collapseHandlers = openList.map((stateTuple) => (e): void => {
      (e.nativeEvent as unknown as Record<string, any>).skipDeselect = true;
      stateTuple[1](!stateTuple[0]);
    }) as MouseEventHandler[];

  let jsxJobs: JSX.Element[] = [];
  if ('fulfilled' === jobsStatus) {
    jsxJobs = _j
      .map((sub, i) => {
        if (!sub.length || !sub[0]) {
          return null;
        }

        const sId = sub[0].statusId;
        return (
          <>
            <ListItemButton key={`job_section_${sId}`} onClick={collapseHandlers[i]}>
              <ListItemButton>{FolderIcons[sId]}</ListItemButton>
              <ListItemText
                primary={JobStatus[sId]}
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              />
              <ListItemText secondary={`(${sub.length})`} />
              <ListItemText>
                {openList[i][0] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemText>
            </ListItemButton>
            <Collapse key={`job_collapse_${sId}`} in={openList[i][0]} unmountOnExit>
              <List>
                {sub.map((job) => (
                  <ListItemButton
                    key={job.id}
                    id={job.id}
                    onClick={handleClick}
                    sx={{ pl: 4 }}
                    disabled={listIsDisabled}
                    selected={currJobId === job.id}
                  >
                    <ListItemText primary={job.userFacingName} />
                    {/* <ListItemText primary={job.userFacingName} secondary={job.name} /> */}
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        );
      })
      .filter((jsx) => !!jsx) as JSX.Element[];
  }

  return (
    <List
      className="job_list"
      subheader={
        <ListSubheader>
          Jobs
          {hasSupervisedJobs ? (
            <Switch
              edge="end"
              checked={showAdmin}
              onChange={(): void => setShowAdmin(!showAdmin)}
            ></Switch>
          ) : null}
        </ListSubheader>
      }
      sx={{ width }}
    >
      {'rejected' === jobsStatus && <ListItemText primary={jobsErr} />}
      {'pending' === jobsStatus && (
        <ListItem>
          <Spinner />
        </ListItem>
      )}
      {jsxJobs}
    </List>
  );
};

export default JobsList;
