/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file imagePromise.ts
 * @desc Created on Wed Sep 07 2022 18:22:59
 * @copyright All rights reserved @ Inarix
 */

import axios, { AxiosRequestConfig } from 'axios';
import { Image, ImageUrlFromAPI } from '../../declarations/Image';
import { imageSignedUrl } from './queries';
import { queryWrap } from './queryWrapper';

/**
 * It takes an array of images and an authHead, and returns a promise that resolves to a dictionary of
 * image IDs to signed URLs
 * @param {Image[]} images - Image[] - an array of images that need to be uploaded
 * @param authHead - AxiosRequestConfig<any>
 * @returns A dictionary of image ids to signed urls.
 */
export async function imagePromiseFactory(
  images: Image[],
  authHead: AxiosRequestConfig<any>,
): Promise<Record<string, string>> {
  const results: Record<string, string> = {};
  await Promise.all(
    images.map((img) => {
      if (img.url) {
        return null;
      }
      return queryWrap(axios.get(imageSignedUrl(img), authHead)).then(
        (result: { data: ImageUrlFromAPI }) => {
          results[img.id] = result.data.signedRequest;
        },
      );
    }),
  );

  return results;
}
